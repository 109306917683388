import { Fragment } from "react";

import { Typography, Tooltip, TableCell, TableRow, Link } from "@mui/material";
import  CheckBoxIcon                    from '@mui/icons-material/CheckBox';
import  LockIcon                        from '@mui/icons-material/Lock';
import  LockOpenIcon                    from '@mui/icons-material/LockOpen';
import  DisabledByDefaultOutlinedIcon   from '@mui/icons-material/DisabledByDefaultOutlined';

import { IEscrowInfo, ITokenInfo }  from "../../interfaces";
import  * as Theme                  from "../EskrowTheme";
import  * as Helpers        from "../../helpers/general/Helpers";
import  * as EscrowHelpers  from "../../helpers/escrow";
import ShortAddress         from "../ShortAddress";
import ShortTxnId           from "../ShortTxnId";
import ColumnDateNIn        from "./ColumnDateNIn";
import ColumnTokenNQty      from "./ColumnTokenNQty";



type ComponentProps = {
    escrowDetails:      IEscrowInfo[];
    connectedWallet:    `0x${string}` | undefined;
    connectedChainId:   number;
    tokenList:          ITokenInfo[];
};
const EscrowList: React.FC<ComponentProps> = ({ escrowDetails, connectedWallet, connectedChainId, tokenList, }) => {
    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();


    const cellContent = {
        color:      "gray",
        fontSize:   isMd ? "10pt" : "7pt",
        margin:     isMd ? "5px" : "2px",
        padding:    isMd ? "5px" : "2px",
    };

    return (
<Fragment>
{
    escrowDetails.map((escrow, index) => {
        const isSelfpartyFullyDeposited     = EscrowHelpers.selfpartyTokenDepositedQty(escrow, connectedWallet)     == EscrowHelpers.selfpartyTokenRequiredQty(escrow, connectedWallet);
        const isCounterpartyFullyDeposited  = EscrowHelpers.counterpartyTokenDepositedQty(escrow, connectedWallet)  == EscrowHelpers.counterpartyTokenRequiredQty(escrow, connectedWallet);
        const {isActive, isEffective, isSettled, isExpired, isPartiallyFunded, 
            isFullyFunded, isParty1Settled, isParty2Settled, isInFundingPeriod, 
            isInSettlementPeriod, isInReleasePeriod
        }                                   = EscrowHelpers.escrowStatus(escrow);
        const isR   = (escrow.isReadyToSettle && !escrow.isFullyWithdrawn);
        return (
            <TableRow
                key={index}
                className={index % 2 === 0 ? 'escrowTableRowEven' : 'escrowTableRowOdd'}
            >
                <TableCell className="escrowTableCell" sx={{...cellContent, textAlign: "left"}}>
                    <Link href={"/escrow?chainId=" + connectedChainId 
                        + "&escrowId=" + escrow.escrowId
                        + "&contractId=" + escrow.contractId
                        } sx={{padding:"0px"}}
                    >
                        <ShortTxnId id={escrow.escrowId} />
                    </Link>
                </TableCell>

                <TableCell className="escrowTableCell" sx={{...cellContent}}>
                    <ShortAddress addr={EscrowHelpers.counterparty(escrow, connectedWallet)} />
                </TableCell>

                <TableCell className="escrowTableCell" sx={{...cellContent}}>
                    <Tooltip title={"Is " + (!isActive ? "NOT " : "") + "Active"} placement="top">
                        <Typography 
                            variant = "caption" 
                            color   = {isActive?"gray":"lightgray"} 
                            fontSize= {"10pt"}>A</Typography>
                    </Tooltip>
                    <Tooltip title={"Is " + (!isEffective ? "NOT " : "") + "Effective"} placement="top">
                        <Typography 
                            variant = "caption" 
                            color   = {isEffective?"gray":"lightgray"} 
                            fontSize= {"10pt"}>E</Typography>
                    </Tooltip>
                    <Tooltip title={"Is " + (isR ? "" : "NOT ") + "Ready to Settle"} placement="top">
                        <Typography
                            variant     = "caption" 
                            color       = {isR ? "green" : "lightgray"} 
                            fontSize    = {isR ? "14pt" : "10pt"} 
                            fontWeight  = {isR ? "bold" : ""}
                        >R</Typography>
                    </Tooltip>
                    <Tooltip title={"Is " + (!escrow.isExpired ? "NOT " : "") + "Expired"} placement="top">
                        <Typography 
                            variant = "caption" 
                            color   = {escrow.isExpired ? "red" : "lightgray"} 
                            fontSize= {"10pt"}>X</Typography>
                    </Tooltip>
                    <Tooltip title={"Is " + (!escrow.isFullyWithdrawn ? "NOT " : "") + "Completed"} placement="top">
                        <Typography 
                            variant     = "caption" 
                            color       = {escrow.isFullyWithdrawn ? "blue" : "lightgray"} 
                            fontSize    = {escrow.isFullyWithdrawn ? "14pt": "10pt"}
                            fontWeight  = {escrow.isFullyWithdrawn ? "bold" : ""}
                        >F</Typography>
                    </Tooltip>
                </TableCell>

                {
                    isMd ? (
                        <>
                            <TableCell className="escrowTableCell" sx={{...cellContent}}>
                                { 
                                    ColumnTokenNQty(Helpers.findTokenFromList( 
                                            EscrowHelpers.selfpartyTokenAddress( escrow, connectedWallet),  tokenList )
                                        , EscrowHelpers.selfpartyTokenRequiredQty( escrow, connectedWallet )
                                        , "#1976d2"
                                    )
                                }
                                <Typography variant="caption" color="gray" sx={{display: "flex", alignItems: "center"}}>
                                    <Tooltip title="Are deposited tokens are locked (from refund)?" placement="top">
                                        {EscrowHelpers.isSelfpartyLockRefund(escrow, connectedWallet)
                                        ? (<LockIcon        style={{fontSize: "14pt"}} />)
                                        : (<LockOpenIcon    style={{fontSize: "14pt"}} />)
                                        }
                                    </Tooltip> {" . "}
                                    <Tooltip title="Has Agreed Qty been FULLY deposited?" placement="top">
                                        {isSelfpartyFullyDeposited
                                        ? (<CheckBoxIcon                    style={{ fontSize: "14pt", color: 'darkgreen' }} />) 
                                        : (<DisabledByDefaultOutlinedIcon   style={{ fontSize: "14pt", color: 'gray' }} />)}
                                    </Tooltip> {" . "}
                                { 
                                    ColumnTokenNQty(Helpers.findTokenFromList( 
                                            EscrowHelpers.selfpartyTokenAddress( escrow, connectedWallet), tokenList )
                                        , EscrowHelpers.selfpartyTokenDepositedQty( escrow, connectedWallet )
                                        , isSelfpartyFullyDeposited ? "green" : "", isMd ? "14pt" : "11pt"
                                    )
                                }
                                </Typography>
                            </TableCell>

                            <TableCell className="escrowTableCell" sx={{...cellContent}}>
                                { 
                                    ColumnTokenNQty(Helpers.findTokenFromList( 
                                            EscrowHelpers.counterpartyTokenAddress( escrow, connectedWallet), tokenList )
                                        , EscrowHelpers.counterpartyTokenRequiredQty( escrow, connectedWallet )
                                        , "#1976d2" 
                                    )
                                }
                                <Typography variant="caption" color="gray" sx={{display: "flex", alignItems: "center"}}>
                                    <Tooltip title="Are deposited tokens are locked (from refund)?" placement="top">
                                        {EscrowHelpers.isCounterpartyLockRefund(escrow, connectedWallet)
                                        ? (<LockIcon        style={{fontSize: "14pt"}} />)
                                        : (<LockOpenIcon    style={{fontSize: "14pt"}} />)
                                        }
                                    </Tooltip> {" . "}
                                    <Tooltip title="Has Agreed Qty been FULLY deposited?" placement="top">
                                        {isCounterpartyFullyDeposited
                                        ? (<CheckBoxIcon                    style={{ fontSize: "14pt", color: 'darkgreen' }} />) 
                                        : (<DisabledByDefaultOutlinedIcon   style={{ fontSize: "14pt", color: 'gray' }} />)}
                                    </Tooltip> {" . "}
                                { 
                                    ColumnTokenNQty(Helpers.findTokenFromList( 
                                            EscrowHelpers.counterpartyTokenAddress( escrow, connectedWallet), tokenList )
                                        , EscrowHelpers.counterpartyTokenDepositedQty( escrow, connectedWallet ) 
                                        , isCounterpartyFullyDeposited ? "green" : "", isMd ? "14pt" : "11pt"
                                    )
                                }
                                </Typography>
                            </TableCell>

                            <TableCell className="escrowTableCell" sx={{...cellContent}}>
                                {ColumnDateNIn(escrow.settleFromEpoch, "Ok to Settle", "green")}
                            </TableCell>

                            <TableCell className="escrowTableCell" sx={{...cellContent}}>
                                {ColumnDateNIn(escrow.expiryEpoch)}
                            </TableCell>
                        </>
                    )
                    : null
                }

                <TableCell className="escrowTableCell" sx={{...cellContent}}>
                    <Typography variant="body2" sx={{fontSize: isMd ? null : "7pt"}}>
                        {escrow.description.slice(0,38) + ((escrow.description.length > 38)? " ...":"")}
                    </Typography>
                </TableCell>
            </TableRow>         
        )
    })
}
</Fragment>

    )
};

export default EscrowList;