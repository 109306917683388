// Interface to https://tokenlists.org/
export interface ITokenInfo {
    chainId:    number;
    name:       string;
    symbol:     string;
    address:    `0x${string}`;
    decimals:   number;
    logoURI:    string;
    tags:       string[];
};

export function newITokenInfo()
{
    const v: ITokenInfo = {
        chainId:    0,
        name:       "",
        symbol:     "",
        address:    "0x0",
        decimals:   1,
        logoURI:    "",
        tags:       [],
    }
    return v;
}