export interface IEscrowInfo {
        escrowId:       `0x${string}`;
        contractId:     number;
        party1:         `0x${string}`;
        party2:         `0x${string}`;
        token1:         `0x${string}`;
        token2:         `0x${string}`;
        token1RequiredQty:          bigint;
        token1DepositedQty:         bigint;
        token2RequiredQty:          bigint;
        token2DepositedQty:         bigint;
        expiryEpoch:                bigint;
        settleFromEpoch:            bigint;
        feeInPpt:                   number;
        isParty1LockRefund:         boolean;
        isParty2LockRefund:         boolean;
        hasParty1Withdrawn:         boolean;
        hasParty2Withdrawn:         boolean;
        description:                string;
        token1FeeInQty:             bigint;
        token2FeeInQty:             bigint;
        isReadyToSettle:            boolean;
        isExpired:                  boolean;
        isFullyWithdrawn:           boolean;
}

export function newIEscrowInfo() {
const escrow: IEscrowInfo = {
        escrowId:       "0x0",
        contractId:     0,
        party1:         "0x0",
        party2:         "0x0",
        token1:         "0x0",
        token2:         "0x0",
        token1RequiredQty:  BigInt(0),
        token1DepositedQty: BigInt(0),
        token2RequiredQty:  BigInt(0),
        token2DepositedQty: BigInt(0),
        expiryEpoch:        BigInt(0),
        settleFromEpoch:    BigInt(0),
        feeInPpt:           0,
        isParty1LockRefund: false,
        isParty2LockRefund: false,
        hasParty1Withdrawn: false,
        hasParty2Withdrawn: false,
        description:        '',
        token1FeeInQty:     BigInt(0),
        token2FeeInQty:     BigInt(0),
        isReadyToSettle:    false,
        isExpired:          false,
        isFullyWithdrawn:   false,
        };
        return escrow;
}