import { Fragment }                         from "react";
import { TableHead, TableRow, TableCell }   from "@mui/material";
import  * as Theme                          from "../EskrowTheme";
import ShortAddress                         from "../ShortAddress";



type ComponentProps = {
    connectedWallet: `0x${string}` | undefined;
};
const EscrowHeader: React.FC<ComponentProps> = ({ connectedWallet, }) => {
    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();

    const cellHeader = {
        borderBottom:   "3px",
        color:          "white",
        fontSize:       isMd ? "9pt" : "7pt",
        verticalAlign:  "bottom",
    };

    return (
<Fragment>
    <TableHead>
        <TableRow>
            <TableCell  rowSpan={isMd ? 2 : 1} className="escrowTableHeader" 
                sx={{...cellHeader, padding: "3px"}}>Escrow Id</TableCell>

            <TableCell  rowSpan={isMd ? 2 : 1} className="escrowTableHeader" 
                sx={{...cellHeader, padding: "3px"}}>Counterparty</TableCell>

            <TableCell  rowSpan={isMd ? 2 : 1} className="escrowTableHeader" 
                sx={{...cellHeader, padding: "3px"}}>Status</TableCell>

            {
                isMd ? (
                    <>
                        <TableCell  rowSpan={1} colSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>
                            You (<ShortAddress addr={connectedWallet} />)
                        </TableCell>

                        <TableCell  rowSpan={1} colSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>
                            Counterparty
                        </TableCell>

                        <TableCell  rowSpan={2} colSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>Earliest<br/>Settlement (UTC)
                        </TableCell>

                        <TableCell  rowSpan={2} colSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>Expiry (UTC)
                        </TableCell>
                    </>
                ) 
                : null 
            }

            <TableCell  rowSpan={isMd ? 2 : 1} colSpan={1} className="escrowTableHeader" 
                sx={{...cellHeader, padding: "3px"}}>Description</TableCell>
        </TableRow>

        {
            isMd ? (
                <>
                    <TableRow>
                        <TableCell  rowSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>
                            Agreed To Send /<br />
                            FUNDED</TableCell>

                        <TableCell  rowSpan={1} className="escrowTableHeader" 
                            sx={{...cellHeader, padding: "3px"}}>
                            Agreed To Send /<br />
                            FUNDED</TableCell>

                    </TableRow>
                </>
            )
            : null
        }       
    </TableHead>
</Fragment>
    )
}

export default EscrowHeader;