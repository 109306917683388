import  { useState, useEffect, Fragment }   from "react";
import  { 
    Typography, Button, TextField, Dialog, DialogTitle,
    DialogContent, DialogActions, CircularProgress 
}                                           from "@mui/material";
import  { IEscrowInfo }                     from "../../interfaces";
import  { ITokenInfo, newITokenInfo }       from "../../interfaces/ITokenInfo";
import  { IDepositProcess }                 from "./Helpers";
import  { 
    fetchAllowanceAsync, fetchWalletBalanceAsync, 
    getTokenInfo, quantityToAmount, selfpartyTokenAddress,
    selfpartyTokenDepositedQty, selfpartyTokenRequiredQty,
    toDecimal, 
}                                           from "../../helpers";
import  ShortAddress                        from "../ShortAddress";
import { FetchBalanceResult } from "@wagmi/core";



type ComponentProps = {
    chainId:                number,
    chainName:              string,
    party:                  `0x${string}`,
    escrow:                 IEscrowInfo,
    tokenList:              ITokenInfo[],
    depositProcess:         IDepositProcess,
    isDepositDialogOpen:    boolean,
    setIsDepositDialogOpen: (value: boolean) => void,
    handleApproveButton:    (party: `0x${string}`, token: ITokenInfo, amount: number, setIsRequestingAllowance: (value: boolean) => void) => void,
    handleDepositButton:    (party: `0x${string}`, token: ITokenInfo, amount: number) => void,
    handleDepositCancelButton: () => void,
};
const DepositModal: React.FC<ComponentProps>     = ({ 
    chainId, chainName, party, escrow, tokenList, depositProcess, 
    isDepositDialogOpen, setIsDepositDialogOpen, 
    handleApproveButton, handleDepositButton,
    handleDepositCancelButton,
}) => 
{

    const [depositAmount, setDepositAmount] = useState("0.0");
    const [maxDeposit, setMaxDeposit]       = useState(0.0);
    const tokenAddr                         = selfpartyTokenAddress(escrow, party) ?? "0x0";
    const token: ITokenInfo | undefined     = getTokenInfo(tokenAddr, tokenList);
    const [allowance, setAllowance]         = useState(0.0);
    const [tokenBalanceInWallet, setTokenBalanceInWallet]   = useState({} as FetchBalanceResult);
    const [isRequestingAllowance, setIsRequestingAllowance] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue    = event.target.value;
        const isValid       = !isNaN(Number(inputValue)) && (Number(inputValue) <= maxDeposit);
        
        if (isValid) 
        {setDepositAmount(inputValue);}
    };



    useEffect(() => {
        if( (tokenAddr !== "0x0") && (party !== "0x0") && (token) ) {
            let alreadyAllowed = 0.0;
            fetchAllowanceAsync( tokenAddr, party, chainId, escrow.contractId).then((allowed) => {
                alreadyAllowed = quantityToAmount(toDecimal(allowed), token?.decimals);
                setAllowance(alreadyAllowed);

                let toDepositAmt = quantityToAmount( toDecimal(
                    BigInt(selfpartyTokenRequiredQty(escrow, party) ?? 0) 
                    - BigInt(selfpartyTokenDepositedQty(escrow, party) ?? 0))
                    , token.decimals
                );
                setMaxDeposit(toDepositAmt);

                if(!isRequestingAllowance) {
                    if(alreadyAllowed !== 0) {
                        toDepositAmt = alreadyAllowed;
                    }
                    setDepositAmount(toDepositAmt.toString());
                }
            });

            fetchWalletBalanceAsync( party, tokenAddr, chainId).then( (balance) => setTokenBalanceInWallet(balance));
        }
    }, [party, token, depositProcess]);



    return (
        <Fragment>
            <Dialog open={isDepositDialogOpen} onClose={() => setIsDepositDialogOpen(false)}>
                <DialogTitle style={{ backgroundColor: '#1976d2', color: 'white', fontSize: "11pt" }}>
                    Fund the Escrow (<ShortAddress addr={party} />) {chainName}
                </DialogTitle>
                <DialogContent sx={{textAlign: "center"}}>
                    <TextField
                        label       = "Token"
                        variant     = "outlined"
                        value       = {getTokenInfo(token?.address ?? "0x0", tokenList)?.symbol}
                        disabled
                        sx          = {{margin: "10px", width: "20%"}}
                    />
                    <TextField
                        label       = {
                            <Typography>
                                Quantity (
                                <span style={{ fontWeight: 'bold' }}>
                                    {tokenBalanceInWallet.formatted}
                                </span>)
                            </Typography>
                        }
                        variant     = "outlined"
                        value       = {depositAmount}
                        helperText  = { 
                            Number(depositAmount) > Number(tokenBalanceInWallet.formatted) 
                            ?   <Typography variant="caption" color={"error"}>
                                    Insufficient balance in wallet.
                                </Typography> 
                            : null
                        }
                        onChange    = {(event) => handleInputChange(event)}
                        sx          = {{margin: "10px", width: "70%",}}
                    />
                    <br />
                    {
                        (depositProcess.message === "")
                        ? null 
                        : (
                            <Typography 
                                variant     = "body2"
                                bgcolor     = {"#ffffc0"} 
                                color       = {"gray"} 
                                fontSize    = {"10pt"} 
                                width       = {"90%"} 
                                textAlign   = {"center"} 
                                padding     = {"10px"} 
                            >
                                {depositProcess.message} <CircularProgress size={16} />
                            </Typography>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick = {() => handleDepositCancelButton()} 
                            color   = "warning"
                            variant = "contained"
                    >
                        Close
                    </Button>
                    <Button onClick = {() => handleApproveButton(party, token ?? newITokenInfo(), Number(depositAmount), setIsRequestingAllowance)} 
                            color   = "primary"
                            disabled= {
                                !token 
                                || depositProcess.isApproving 
                                || depositProcess.isFunding
                                || (allowance >= Number(depositAmount))
                                || Number(depositAmount) > Number(tokenBalanceInWallet.formatted)
                            }
                            variant = "contained"
                    >
                        Approve
                    </Button>
                    <Button onClick = {() => handleDepositButton(party, token ?? newITokenInfo(), Number(depositAmount))} 
                            color   = "success"
                            disabled= {
                                !token 
                                || depositProcess.isFunding 
                                || depositProcess.isApproving 
                                || (Number(depositAmount) <= 0) 
                                || (allowance < Number(depositAmount))
                                || Number(depositAmount) > Number(tokenBalanceInWallet.formatted) 
                            }
                            variant = "contained"
                    >
                        Fund
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default DepositModal;