import * as React from 'react';
import dayjs, { Dayjs }         from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs }         from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker }       from '@mui/x-date-pickers/DateTimePicker';



type ComponentProps = {
    label?:         string,
    defaultValue?:  Dayjs,
    onChange?:      (dt: Dayjs | null) => void,
    readOnly?:      boolean,
    disabled?:      boolean,
};
const MUIDateTimePicker: React.FC<ComponentProps> = ({ label, defaultValue, onChange, readOnly, disabled }) =>  {
    const [pickedDateTime, setPickedDateTime] = React.useState<Dayjs | null>(defaultValue?defaultValue:null);
    const handleChange = (newValue: Dayjs | null)   => {
        setPickedDateTime(newValue);
        onChange?.(newValue);
    };

  return (
    <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker 
                readOnly    = {readOnly}
                label       = {label}
                value       = {pickedDateTime}
                onChange    = {handleChange}
                sx          = {{width: '100%', "& .MuiInputBase-input": {fontSize: "10pt",}
                }}
                disabled    = {disabled}
            />
        </LocalizationProvider>
    </React.Fragment>
  );
};

export default MUIDateTimePicker;