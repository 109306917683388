
import  { ITokenInfo }      from '../../interfaces/ITokenInfo';
import  { isAddress, isSame }       from '../../helpers';
import  { Dayjs,  }         from 'dayjs';


// type definition of the NewEscrowForm component properties
export type FormContractProps = {
    tokenList:                  ITokenInfo[],
    connectedChainId:           number,
    connectedWallet:            `0x${string}`,
    isCreatingNewEscrowFlag:    boolean,
    createButtonOnClickHandler?:() => void,
    createdEscrowId:            `0x${string}` | null,

    party1:             string,
    setParty1:          (value: string) => void,

    party2:             string,
    setParty2:          (value: string) => void,

    token1:             ITokenInfo | null,
    setToken1:          (value: ITokenInfo | null) => void,

    token2:             ITokenInfo | null,
    setToken2:          (value: ITokenInfo | null) => void,

    token1RequiredQty:      number,
    setToken1RequiredQty:   (value: number) => void,

    token2RequiredQty:      number,
    setToken2RequiredQty:   (value: number) => void,

    party1LockRefund:       boolean,
    setParty1LockRefund:    (value: boolean) => void,

    party2LockRefund:       boolean,
    setParty2LockRefund:    (value: boolean) => void,
    
    isImmediatelyExec:      boolean,
    setIsImmediatelyExec:   (value: boolean) => void,

    settleFrom:             Dayjs,
    setSettleFrom:          (value: Dayjs) => void,

    expiry:                 Dayjs,
    setExpiry:              (value: Dayjs) => void,

    description:            string,
    setDescription:         (value: string) => void,
};


// Disallow - Validation  for RequiredQty
export const handleChangeOnTokenRequiredQty = (
    value:                  string,
    setTokenRequiredQty:    (value: number) => void,
) => 
{   
    if( value.includes("-") ) {
        setTokenRequiredQty(0.0);
    }
    else {
        value = ((value === "") ? "0.0" : value);
        setTokenRequiredQty(parseFloat(value));
    }
};



// Input Error Checking
export const isAllInputValid = (
    party1:             string,
    party2:             string, 
    token1:             ITokenInfo | null,
    token2:             ITokenInfo | null,
    token1RequiredQty:  number,
    token2RequiredQty:  number,
    settleFrom:         Dayjs,
    expiry:             Dayjs,
) => 
{
    let isValid = true;
    if(
        !isAddress(party1) 
        || !isAddress(party2) 
        || isSame(party1, party2)
        || (token1 === null)
        || (token2 === null)
        || (token1RequiredQty+token2RequiredQty === 0)
        || !settleFrom.isValid()
        || !expiry.isValid()
        || (expiry < settleFrom)
    ) {
        isValid = false;
    }
    return isValid ;
}