import  { useEffect, }                  from "react";
import  { Button, Typography, Grid, }   from "@mui/material";

import  * as Helpers                    from "../../helpers/general/Helpers";
import  { IExtendedChainInfo }          from "../../interfaces";



type ComponentProps = {
    setPageName?: (newValue: string) => void;
    seoChain?: IExtendedChainInfo,
};
const ContentProject: React.FC<ComponentProps> = ({ setPageName, seoChain }) => {

    Helpers.setPageMeta({
        title:          `About Eskrow | ${seoChain?.chainName}`,
        description:    `dApp for Trustless ERC-20 Token Escrow on ${seoChain?.chainName}`,
        canonical:      location.href,
    });


    // Onload ...
    // useEffect(() => {
    //     setPageName?.("The Project");
    // }, []);



return (
    <>
        <br/><br/><br/>
        <Grid container spacing={2} sx={{color: "#303030"}}>
            <Grid item xs={12} margin={"50px"} border={"1px solid black"}>
                <Typography variant="h3">
                    <b>WHAT</b> Is Eskrow? ({seoChain?.chainName})
                </Typography>
                <br />
                <Typography variant="body1">
                Eskrow is a dApp {Helpers.randomOne(["providing", "offering"])} trustless 
                escrow {Helpers.randomOne(["smart contract", "service"])} for ANY ERC-20 token 
                on the {seoChain?.chainName} (and other EVM blockchains).
                <br /><br />
                {Helpers.randomOne(["It", "Eskrow"])} offers a secure and 
                reliable {Helpers.randomOne(["dApp", "paltform"])} for the 
                exchange, or transfer of, tokens on the {seoChain?.chainName} blockchain, 
                in addtion to Ethereum, BNB Smart Chain, Polygon/Matic, 
                Avalanche, Optimism, Fantom, and more.
                <br /><br />
                As a crypto escrow {Helpers.randomOne(["dApp", "service"])}, Eskrow serves as an 
                impartial {Helpers.randomOne(["party", "intermediary", "agent"])}, to 
                temporarily {Helpers.randomOne(["safe guard", "hold"])} the 
                agreed {seoChain?.chainName}-based ERC-20 {Helpers.randomOne(["crypto", "tokens", "assets"])} until 
                the {Helpers.randomOne(["conditions", "terms"])} of the escrow agreement are fulfilled. 
                This {Helpers.randomOne(["safeguards", "protects"])} both the 
                parties involved, from {Helpers.randomOne(["possible", "potential"])} fraud and ensures 
                the successful completion of the {Helpers.randomOne(["whole", "entire"])} transaction, 
                while {Helpers.randomOne(["ensuring", "maintaining"])} a trustless environment where 
                neither party needs to place explicit trust in the other.
                <br /><br />
                Eskrow {Helpers.randomOne(["works", "operates"])} {Helpers.randomOne(["using", "through"])} smart
                contracts on {seoChain?.chainName}, {Helpers.randomOne(["creating", "enabling"])} an 
                unbiased and automated process that {Helpers.randomOne(["removes", "eliminates"])} the 
                {Helpers.randomOne(["need", "necessity"])} for third-party intermediaries. This 
                on-chain innovation eliminates the need for centralized exchanges, custody services, 
                or legal interventions, making the entire process more efficient, secure, 
                and transparent compared to traditional escrow services.
                </Typography>
                <br /><br /><br />


                <Typography variant="h3">
                    <b>WHY</b> Use Eskrow? ({seoChain?.chainName})
                </Typography>
                <br />
                <Typography variant="body1">
                A trustless crypto escrow service is essential when exchanging tokens between 
                two parties on the {seoChain?.chainName} blockchain, not because we doubt the integrity 
                of our counterparty, but as a prudent measure to prevent potential adversities.
                <br /><br />
                Cryptocurrency transactions on {seoChain?.chainName} often involve significant sums and 
                inherently carry risks. By using Eskrow, an on-chain, trustless escrow service 
                on {seoChain?.chainName}, we mitigate these risks and ensure that both parties fulfill 
                their obligations without the temptation of malfeasance. This is also part of the
                corporate risk management requirements.
                <br /><br />
                Using smart contracts on {seoChain?.chainName}, Eskrow will only allows for settlement, 
                when the agreed conditions are met, thus leaving no room for coercion or 
                opportunistic behavior.
                <br /><br />
                With this, Eskrow safeguards both parties from unforeseen circumstances and 
                ensures the smooth execution of token exchanges, or transfers.
                <br /><br />
                Furthermore, Eskrow is <b>FREE</b> to use. You only pay for gas fee to interact 
                with the smart contracts. Eskrow is also open source (GNU GPL v2 license), allowing 
                the community to scrutinize the smart contracts to ensure that everything is proper 
                and is should-be. There is <b>NO</b> ESKROW token in the market (this service is 
                free).
                </Typography>
                <br /><br /><br />
                <Typography variant="h3">
                    <b>TRY IT</b> On Testnet
                </Typography>
                <br />
                <Typography variant="body1">
                    Eskrow is deployed on the <b>Sepolia Testnet</b>. So, you can test Eskrow on 
                    Sepolia to familiarized yourself, and/or understand how it works.
                    <br /><br />
                    Contact Support on Telegram, and we will be happy to send you 
                    some SepoliaETH, and some Test Tokens.
                </Typography>
                <br /><br /><br />.
            </Grid>
        </Grid>
    </>
)
}
export default ContentProject;