import  { useState, useEffect } from 'react';

import  { mainnet }             from 'wagmi/chains';
import  { connect }             from '@wagmi/core';
import  { InjectedConnector }   from '@wagmi/core/connectors/injected';
import  { getNetwork }          from '@wagmi/core';
import  { getAccount }          from '@wagmi/core';
import  { switchNetwork }       from '@wagmi/core';
import  { watchNetwork }        from '@wagmi/core';
import  { watchAccount }        from '@wagmi/core';

import  { extendedChainInfos }  from "../helpers";
import  * as Helpers            from "../helpers/general/Helpers";
import  { ITokenInfo }          from '../interfaces/ITokenInfo';



export function useWeb3ClientLogic(pageName: string) {
    const [tokenList, setTokenList]         = useState<ITokenInfo[]>([]);

    // Web3 Client Related
    const { chains: wagmiChains, chain: connectedChain }    = getNetwork();
    
    const allChains         = [...wagmiChains, mainnet];  // result is [] if not connected, so, add mainnet as default
    const connectedChainId  = connectedChain? connectedChain.id : 1;
    const connectedChainName= connectedChain? connectedChain.name : "Ethereum";
    const connectedWallet   = getAccount().address? getAccount().address : "0x0";
    const urlChainId        = parseInt( Helpers.getUrlParameter("chainId", 1) );
    
    const urlChainName      = (extendedChainInfos.find((c) => c.chainId === urlChainId)?.chainName) as string | undefined;
    const canonical         = undefined; //Helpers.buildRedirectUrl(pageName, [{key: "chainId", value: urlChainId}], false, true);



    // Onload ...
    useEffect(() => {Helpers.fetchTokensDebouncedAsync(connectedChainId, setTokenList)}, []);

    // Triggered whenever user try to change the chain via chainId url parameter
    useEffect(() => {
        async function switchNetworkWrapper() {
            if(connectedChain) {
                try {await connect({connector: new InjectedConnector()});} catch (err) {};
                try {await switchNetwork({chainId: urlChainId});} catch (err) {};
            }
        };
        if( connectedChainId !== urlChainId ) {switchNetworkWrapper();};
    }, [switchNetwork]);

    // Triggered when Account or Chain is changed/Disconnected (via Metamask)
    const unwatchNetwork = watchNetwork((network) => {
        if(connectedChainId !== network.chain?.id) {
            const requestedChainId = network.chain? network.chain.id : 1;
            const redirect = Helpers
                            .buildUrl("/app", [["chainId", requestedChainId.toString()]]);

           window.location.href = redirect.toString();
        }
        Helpers.fetchTokensDebouncedAsync(network.chain?.id, setTokenList);
    });
    const unwatchAccount = watchAccount((account) => {
    })
    // -----

    return {
        allChains, supportedChains: extendedChainInfos,
        connectedChain, connectedChainId, connectedChainName, 
        connectedWallet, 
        urlChainId, urlChainName, tokenList, setTokenList, canonical,
    }
}
