import  dayjs           from 'dayjs';
import  utc             from 'dayjs/plugin/utc';

import { ITokenInfo }   from "../../interfaces/ITokenInfo";
import { IEscrowInfo }  from "../../interfaces/IEscrowInfo";
import { IEskrowContract, IEskrowEventError }   from '../../interfaces';
import { isSame }                               from "../general";
import { eskrowAbi, eskrowContracts }           from '../../abi/EskrowAbi';

dayjs.extend(utc);



export function getEskrowContractLatest(contracts: IEskrowContract[]): IEskrowContract | undefined {
        if (contracts.length === 0) {
            return undefined;
        }
    
        const sortedContracts = contracts.slice().sort((a, b) => b.id - a.id);
        return sortedContracts[0];
}

export function getEskrowContracts( chainId: number ) {
        const founds = eskrowContracts.filter( (c) => c.chainId === chainId );
        return {contracts: founds,  abi: eskrowAbi};
}



export function selfparty(d: IEscrowInfo | undefined | null, partyOfInterest: `0x${string}` | undefined) {
        if(d === undefined) {return undefined};

        if( partyOfInterest === d?.party1)
                return d?.party1;
        if( partyOfInterest === d?.party2)
                return d?.party2;
        return  undefined;
}
export function counterparty(d: IEscrowInfo | undefined | null, partyOfInterest: `0x${string}` | undefined) {
        if(d === undefined) {return undefined};

        if( partyOfInterest === d?.party1)
                return d?.party2;
        if( partyOfInterest === d?.party2)
                return d?.party1;
        return  undefined;
}
export function selfpartyTokenAddress(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token1;
    if( partyOfInterest === d.party2)
            return d.token2;
    return  undefined;
}
export function counterpartyTokenAddress(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token2;
    if( partyOfInterest === d.party2)
            return d.token1;
    return  undefined;
}
export function selfpartyTokenRequiredQty(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token1RequiredQty;
    if( partyOfInterest === d.party2)
            return d.token2RequiredQty;
    return  undefined;
}
export function counterpartyTokenRequiredQty(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token2RequiredQty;
    if( partyOfInterest === d.party2)
            return d.token1RequiredQty;
    return  undefined;
}
export function selfpartyTokenDepositedQty(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token1DepositedQty;
    if( partyOfInterest === d.party2)
            return d.token2DepositedQty;
    return  undefined;
}
export function counterpartyTokenDepositedQty(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
    if( partyOfInterest === d.party1)
            return d.token2DepositedQty;
    if( partyOfInterest === d.party2)
            return d.token1DepositedQty;
    return  undefined;
}
export function isSelfpartyLockRefund(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
        if( partyOfInterest === d.party1)
                return d.isParty1LockRefund;
        if( partyOfInterest === d.party2)
                return d.isParty2LockRefund;
        return  undefined;
}
export function isCounterpartyLockRefund(d: IEscrowInfo, partyOfInterest: `0x${string}` | undefined) {
        if( partyOfInterest === d.party2)
                return d.isParty1LockRefund;
        if( partyOfInterest === d.party1)
                return d.isParty2LockRefund;
        return  undefined;
}

export function sortEscrowsByEarliestDescThenExpiryDesc(escrows: (IEscrowInfo | null)[] | undefined) {
        if (escrows === undefined) {return [];}
        const nullRemoved = escrows.filter(item => item !== null) as IEscrowInfo[];
        const sorted = nullRemoved.sort((a, b) => {
            if          (a.settleFromEpoch > b.settleFromEpoch) {return -1;} 
            else if     (a.settleFromEpoch < b.settleFromEpoch) {return 1;} 
            else {
                // If settleFromEpoch values are equal, sort by expiryEpoch
                if      (a.expiryEpoch > b.expiryEpoch) {return -1;} 
                else if (a.expiryEpoch < b.expiryEpoch) {return 1;} 
                else    {return 0;}
            }
        });
        return sorted;
}

export function partyRelationship( 
        party: number,
        party1: `0x${string}` | undefined, 
        party2: `0x${string}` | undefined, 
        connectedWallet: `0x${string}` | undefined) {

        const self      = party==1 ? party1 : party2;
        const counter   = party==1 ? party2 : party1;

        if(     !isSame(connectedWallet, self ) 
                && !isSame(connectedWallet, counter)
        ) {return "";}

        if(     isSame(connectedWallet, self)) {return "YOU";}
        return  "COUNTERPARTY";
}

export function getTokenInfo( token: `0x${string}` | undefined, tokenList: ITokenInfo[] | null) {
        if(tokenList === undefined)
        {return undefined}

        const found = tokenList?.find((t) => isSame(t.address, token));
        return found;
}



export function escrowStatus( escrow: IEscrowInfo | null | undefined ) {
        const isExpired                 = escrowStatusIsExpired(escrow);
        const isPartiallyFunded         = escrowStatusIsPartiallyFunded(escrow);
        const isFullyFunded             = escrowStatusIsFullyFunded(escrow);
        const isParty1Settled           = escrowStatusIsParty1Settled(escrow);
        const isParty2Settled           = escrowStatusIsParty2Settled(escrow);
        const isSettled                 = escrowStatusIsSettled(escrow);
        const isActive                  = escrowStatusIsActive(escrow);
        const isEffective               = escrowStatusIsEffective(escrow);
        const isInFundingPeriod         = !isFullyFunded        && !isExpired   && !isSettled;
        const isInSettlementPeriod      = !escrow ? false
                                        : isFullyFunded && !isSettled
                                        && (dayjs().utc().unix() >= escrow?.settleFromEpoch)
        const isInReleasePeriod         = !escrow ? false
                                        :       isExpired 
                                                && !isSettled 
                                                && (isPartiallyFunded || !isFullyFunded);

        return {
                isActive, isEffective, isSettled, isExpired,
                isPartiallyFunded, isFullyFunded,
                isParty1Settled, isParty2Settled,
                isInFundingPeriod, isInSettlementPeriod, isInReleasePeriod
        }
}
export const escrowStatusIsExpired = (escrow: IEscrowInfo | null | undefined) => {
        return  escrow?.isExpired ?? false;
}
export const escrowStatusIsPartiallyFunded = (escrow: IEscrowInfo | null | undefined) => {
        return  !escrow
                ? false
                : (     (escrow?.token1DepositedQty > 0) 
                        && (escrow?.token1DepositedQty < escrow?.token1RequiredQty)
                );
}
export const escrowStatusIsFullyFunded = (escrow: IEscrowInfo | null | undefined) => {
        return  !escrow ? false
                : (escrow.token1RequiredQty + escrow.token2RequiredQty)
                === (escrow.token1DepositedQty + escrow.token2DepositedQty);
}
export const escrowStatusIsParty1Settled = (escrow: IEscrowInfo | null | undefined) => {
        return escrow?.hasParty1Withdrawn ?? false;
}
export const escrowStatusIsParty2Settled = (escrow: IEscrowInfo | null | undefined) => {
        return escrow?.hasParty2Withdrawn ?? false;
}
export const escrowStatusIsSettled = (escrow: IEscrowInfo | null | undefined) => {
        return escrowStatusIsParty1Settled(escrow) && escrowStatusIsParty2Settled(escrow);
}
export const escrowStatusIsActive = (escrow: IEscrowInfo | null | undefined) => {
        return escrowStatusIsSettled(escrow) 
                ? false 
                : ((escrowStatusIsExpired(escrow) && !escrowStatusIsFullyFunded(escrow)) 
                        ? false 
                        : true
                );
}
export const escrowStatusIsEffective = (escrow: IEscrowInfo | null | undefined) => {
        return escrowStatusIsFullyFunded(escrow) && !escrowStatusIsSettled(escrow);
}



const contractErrors: IEskrowEventError[] = [
        {method: "create", code: 100020, text: "Invalid addresses. Parties and Tokens must not be 0x0."},
        {method: "create", code: 100030, text: "Parties must not be the same."},
        {method: "create", code: 100040, text: "At least one of the Required Qty must be non-Zero."},
        {method: "create", code: 100050, text: "Expiry must be in the future."},
        {method: "create", code: 100060, text: "Earliest Settlement must be before Expiry."},

        {method: "remove", code: 101020, text: "Only Party1 or Party2 can remove escrow."},
        {method: "remove", code: 101030, text: "Escrow is funded. Only settled/expired escrow can be removed."},

        {method: "deposit", code: 102020, text: "Escrow has Expired."},
        {method: "deposit", code: 102030, text: "Party-Token does not match Escrow."},
        {method: "deposit", code: 102040, text: "Cannot over-fund the escrow."},

        {method: "refund", code: 103020, text: "Only parties of the escrow can perform refund."},
        {method: "refund", code: 103030, text: "Your fund is Locked as part of escrow terms. Either settle, or wait until expiry."},
        {method: "refund", code: 103040, text: "Escrow already settled."},

        {method: "settle", code: 104020, text: "Only party of the escrow can settle."},
        {method: "settle", code: 104020, text: "Escrow not ready to settle (check funding, or earliest settle date/time."},
]
export function getEskrowError( code: number )
{
        return contractErrors.find( (i) => i.code == code );
}