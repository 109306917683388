import { Typography }   from "@mui/material";
import { ITokenInfo }   from "../../interfaces/ITokenInfo";
import { quantityToAmount, toDecimal } from "../../helpers";



const ColumnTokenNQty = (
    token:      ITokenInfo | undefined, 
    qty:        bigint | undefined = BigInt(0), 
    color:      string = "",
    fontSize:   string = "",
) => {
    return (
        !token ? null
        : (
            <Typography variant="body1" color={color} fontSize={fontSize}>
                {token.symbol} {" "}
                {quantityToAmount(toDecimal(qty), token?.decimals).toLocaleString()}
            </Typography>
        )
    )
}

export default ColumnTokenNQty;