import  { useEffect, }          from "react";
import  { Button, Typography, } from "@mui/material";

import  * as Helpers            from "../../helpers/general/Helpers";
import  { useWeb3ClientLogic }  from "../../hooks";



type ComponentProps = {
    setPageName?: (newValue: string) => void;
};
const ContentMissing: React.FC<ComponentProps> = ({ setPageName }) => {

    const { urlChainId, urlChainName, canonical} = useWeb3ClientLogic("");
    const urlEscrowId = Helpers.getUrlParameter("escrowId");

    Helpers.setPageMeta({
        title:          "Missing Escrow | Eskrow",
        description:    `${urlChainName} | Missing EscrowId ${urlEscrowId}`,
        canonical:      canonical,
    });
    
    

    // Onload ...
    useEffect(() => {
        setPageName?.("Missing Escrow");
    }, []);



    return (
        <div>
            {/* ----- MAIN Display area */}
            <br/><br/><br/><br/>
            <Typography variant="h2">Missing Escrow</Typography>
            <Typography variant="h6">{urlEscrowId}</Typography><br />

            The EscrowId you requested does not exists, or has been removed after cleaning process.
            <br/><br/><br />
            <Button variant = "contained"
                    size    = "large"
                    sx      = {{fontSize: "14pt"}}
                    onClick = {() => {
                        const url = Helpers.buildUrl("/app", [["chainId", urlChainId.toString()]]);
                        window.location.href = url.toString();
                    }}
            >
                Launch App
            </Button>

        </div>
    );
};

export default ContentMissing;