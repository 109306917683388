import  React, { useState }                         from "react";
import  { BrowserRouter as Router, Routes, Route }  from "react-router-dom";
import  { ThemeProvider, CssBaseline, Container }   from '@mui/material';

import  { getExtendedChainInfo, supportedChains }   from "./helpers";
import  {extendedChainInfos}  from "./helpers";
import  TitleBar              from "./components/TitleBar";
import  ContentMain           from "./components/ContentMain/ContentMain";
import  ContentApp            from "./components/ContentApp/ContentApp";
import  ContentEscrow         from "./components/ContentEscrow/ContentEscrow";
import  ContentNewEscrow      from "./components/ContentNewEscrow/ContentNewEscrow";
import  ContentMissing        from "./components/ContentMissing/ContentMissing";
import  ContentProject        from "./components/ContentProject/ContentProject";
import  ContentSource         from "./components/ContentSource/ContentSource";
import  Footer                from "./components/Footer";
import  * as Theme            from "./components/EskrowTheme";
import  "./App.css";


// // -----
// // Setup Web3 via web3modal / wagmi
import { 
  mainnet, bsc, polygon, arbitrum, avalanche, optimism, 
  fantom, cronos, klaytn, canto, moonbeam, sepolia,
}                                                     from 'wagmi/chains';
import { createWeb3Modal, defaultWagmiConfig }        from '@web3modal/wagmi/react';
import { walletConnectProvider }                      from '@web3modal/wagmi';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { infuraProvider }                             from 'wagmi/providers/infura';
import { alchemyProvider }                            from 'wagmi/providers/alchemy';
import { publicProvider }                             from 'wagmi/providers/public';

// import { WalletConnectConnector }                     from 'wagmi/connectors/walletConnect';
// import { InjectedConnector }                          from 'wagmi/connectors/injected';


// 1. Get projectId
// https://cloud.walletconnect.com/sign-in
const projectId = process.env.REACT_APP_w3m_apiKey ?? "";

// 2. Create wagmiConfig
const { chains, publicClient } 
= configureChains(
  supportedChains,
  [
    walletConnectProvider({ projectId }),
    infuraProvider({ apiKey: process.env.REACT_APP_infura_apikey_mainnet ?? "" }),
    publicProvider(),
  ]
)

const metadata = {
  name:         'Eskrow',
  description:  'Trustless Crypto Escrow dApp',
  url:          'https://eskrow.la/',
  icons:        ['https://eskrow.la/static/media/logo_Eskrow.7e8c6ead92c60485c83b.png']
}

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: [
//     new InjectedConnector({ chains, options: { shimDisconnect: true } }),
//     new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
//   ],
//   publicClient
// })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains, 
  themeVariables: {
    '--w3m-accent':               "cornflowerblue",
    '--w3m-color-mix':            "black",
    '--w3m-color-mix-strength':   50,
    '--w3m-border-radius-master': "1px",
    '--w3m-font-size-master':     '7',
  },
  featuredWalletIds: [
    "aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588",
  ] });



// ==========
// MAIN ENTRY POINT

function App(): JSX.Element {
  Theme.createEskrowTheme();
  const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
  const pageTitle = () => {
    if( location.pathname.startsWith("/app") )            {return "App";}
    if( location.pathname.startsWith("/escrow") )         {return "Escrow";}
    if( location.pathname.startsWith("/newEscrow") )      {return "New";}
    if( location.pathname.startsWith("/project") )        {return "Project";}
    if( location.pathname.startsWith("/smartContract") )  {return "Smart Contract";}
    if( location.pathname.startsWith("/missing") )        {return "Not Found";}
    return "2023";
  }
  
  return (
    <Router>
      <ThemeProvider theme={eskrowTheme}>
        <CssBaseline />
        <WagmiConfig config={wagmiConfig}>
          <Container maxWidth={"xl"}>
            <TitleBar pageTitle={pageTitle()}/>
            <Routes>
              <Route path="/"           element={ <ContentMain      seoChain={getExtendedChainInfo(mainnet)}/> } />
              <Route path="/bsc"        element={ <ContentMain      seoChain={getExtendedChainInfo(bsc)}/> } />
              <Route path="/polygon"    element={ <ContentMain      seoChain={getExtendedChainInfo(polygon)}/> } />
              <Route path="/arbitrum"   element={ <ContentMain      seoChain={getExtendedChainInfo(arbitrum)}/> } />
              <Route path="/avalanche"  element={ <ContentMain      seoChain={getExtendedChainInfo(avalanche)}/> } />
              <Route path="/optimism"   element={ <ContentMain      seoChain={getExtendedChainInfo(optimism)}/> } />
              <Route path="/moonbeam"   element={ <ContentMain      seoChain={getExtendedChainInfo(moonbeam)}/> } />
              <Route path="/sepolia"    element={ <ContentMain      seoChain={getExtendedChainInfo(sepolia)}/> } />
              <Route path="/fantom"     element={ <ContentMain      seoChain={getExtendedChainInfo(fantom)}/> } />

              <Route path="/app"            element={ <ContentApp       /> } />
              <Route path="/newEscrow"      element={ <ContentNewEscrow /> } />
              <Route path="/escrow"         element={ <ContentEscrow    /> } />
              <Route path="/smartContract"  element={ <ContentSource    supportedChains={extendedChainInfos}/> } />
              <Route path="/missing"        element={ <ContentMissing   /> } />

              <Route path="/project"            element={ <ContentProject seoChain={getExtendedChainInfo(mainnet)}/> } />
              <Route path="/project/bsc"        element={ <ContentProject seoChain={getExtendedChainInfo(bsc)}/> } />
              <Route path="/project/polygon"    element={ <ContentProject seoChain={getExtendedChainInfo(polygon)}/> } />
              <Route path="/project/arbitrum"   element={ <ContentProject seoChain={getExtendedChainInfo(arbitrum)}/> } />
              <Route path="/project/avalanche"  element={ <ContentProject seoChain={getExtendedChainInfo(avalanche)}/> } />
              <Route path="/project/optimism"   element={ <ContentProject seoChain={getExtendedChainInfo(optimism)}/> } />
              <Route path="/project/moonbeam"   element={ <ContentProject seoChain={getExtendedChainInfo(moonbeam)}/> } />
              <Route path="/project/sepolia"    element={ <ContentProject seoChain={getExtendedChainInfo(sepolia)}/> } />
              <Route path="/project/fantom"     element={ <ContentProject seoChain={getExtendedChainInfo(fantom)}/> } />
            </Routes>
          </Container>
        </WagmiConfig>

        <Footer />
      </ThemeProvider>
    </Router>
  );
};
export default App;
