import  { isAddress }   from "../helpers";



type ComponentProps = {
    addr: `0x${string}` | undefined,
};
const ShortAddress: React.FC<ComponentProps> = ({ addr }) =>  {
    var display: string = "Please Connect";

    if(isAddress(addr)) {
        if(addr === "0x0") {addr = "0x0000"};
        display = addr?.slice(0,5) + "..." + addr?.slice(-4);
    }

    return (
        <span>{display}</span>
    );
};

export default ShortAddress;