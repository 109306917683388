import  { useState, useEffect } from "react";
import  {
    Typography, Button, Dialog, 
    DialogTitle, DialogContent, 
    DialogActions, CircularProgress, Snackbar, 
}                               from "@mui/material";

import  dayjs, { Dayjs,  }      from 'dayjs';
import  utc                     from 'dayjs/plugin/utc';

import  { waitForTransaction }  from '@wagmi/core';
import  { watchNetwork }        from '@wagmi/core';
import  { watchAccount }        from '@wagmi/core';

import  { ITokenInfo }          from "../../interfaces/ITokenInfo";
import  * as Helpers            from "../../helpers/general/Helpers";
import  * as Theme              from "../EskrowTheme";
import  { 
    callCreateEskrowAsync,
    watchEkrowEventAsync, 
}                               from "../../helpers";
import  { useWeb3ClientLogic }  from "../../hooks";

import  NewEscrowForm           from './NewEscrowForm';
import  ShortAddress            from "../ShortAddress";



type ComponentProps = {
    setPageName?: (newValue: string) => void;
};
const ContentNewEscrow: React.FC<ComponentProps>     = ({ setPageName }) => {
    dayjs.extend(utc);

    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();

    const [isCreatingNewEscrowFlag, setIsCreatingNewEscrowFlag] = useState<boolean>(false);
    const [isSubmittedToChainFlag, setIsSubmittedToChainFlag]   = useState<boolean>(false);

    const [isInfoDialogShowSpinner, setIsDepositDialogShowSpinner]   = useState(true);
    const [isInfoDialogOpen, setIsInfoDialogOpen]       = useState(false);
    const [infoDialogMessage, setInfoDialogMessage]     = useState("");
    const handleInfoDialogCloseButton = () => {
        setIsInfoDialogOpen(false);
        setIsDepositDialogShowSpinner(true);
    }

    const [isSnackbarOpen, setIsSnackbarOpen]               = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage]             = useState<string>("empty");
    const [snackbarShowDuration, setSnackbarShowDuration]   = useState(5000);
    const [snackbarFlashColor, setSnackbarFlashColor]       = useState<string>("green");
    const handleCloseSnackbar = () => {
        setIsSnackbarOpen(false);
        setSnackbarMessage("");
        setSnackbarFlashColor("green");
        setSnackbarShowDuration(5000);
    };


    // Variables for storing the Escrow
    const [party1, setParty1]                       = useState('');
    const [party2, setParty2]                       = useState('');
    const [token1, setToken1]                       = useState<ITokenInfo | null>(null);
    const [token2, setToken2]                       = useState<ITokenInfo | null>(null);
    const [token1RequiredAmt, setToken1RequiredAmt] = useState<number>(0.0);
    const [token2RequiredAmt, setToken2RequiredAmt] = useState<number>(0.0);
    const [party1LockRefund, setParty1LockRefund]   = useState(false);
    const [party2LockRefund, setParty2LockRefund]   = useState(false);
    const [isImmediatelyExecutable, setIsImmediatelyExecutable] = useState<boolean>(true);
    const [settleFrom, setSettleFrom]               = useState<Dayjs>(dayjs().set('second', 0));
    const [expiry, setExpiry]                       = useState<Dayjs>(dayjs().add(1, "day").set('second', 0));
    const [description, setDescription]             = useState('');



    // Web3 Client related logic
    const {
        supportedChains, connectedChainId, connectedChainName, 
        connectedWallet, urlChainName, urlChainId,
        tokenList, setTokenList, canonical
    } = useWeb3ClientLogic("newEscrow");

    const unwatchNetwork = watchNetwork((network) => {clearForm()});
    const unwatchAccount = watchAccount((account) => {clearForm()});
    // ---

    Helpers.setPageMeta({
        title:          "Eskrow | Create Escrow " + (urlChainName ? ("(" + connectedChainName + ")") : ""),
        description:    "Create a FREE escrow contract on " + urlChainName,
        canonical:      canonical,
    });



    // -----
    // MAIN ENTRY POINT (when Submit button is clicked)
    function createNewEscrowHandler() {
        try {
            callCreateEskrow();

        } catch (error) {
            console.log("Error POINT 10215: ContentNewEscrow.", error);
        } finally {
        }
    }

    let unwatchCreateOk: (() => void) | null;
    let unwatchCreateError: (() => void) | null;
    const [createdEscrowId, setCreatedEscrowId] = useState<`0x${string}` | null>(null);
    async function callCreateEskrow() {
        try {
            setIsCreatingNewEscrowFlag(true);
            setInfoDialogMessage("APPROVE the Escrow Creation at your wallet client. ");
            setIsInfoDialogOpen(true);
            const hash = await callCreateEskrowAsync(
                Helpers.defaultAddressIfInvalid(party1),                        // party1
                Helpers.defaultAddressIfInvalid(party2),                        // party2
                Helpers.defaultAddressIfInvalid(token1?.address),               // token1
                Helpers.amountToQuantity(token1RequiredAmt, token1?.decimals),  // token1RequiredQty
                Helpers.defaultAddressIfInvalid(token2?.address),               // token2
                Helpers.amountToQuantity(token2RequiredAmt, token2?.decimals),  // token2RequiredQty
                party1LockRefund, party2LockRefund,                             // partyIsLockRefund
                BigInt(expiry.utc().unix()),                                    // expiry
                BigInt( isImmediatelyExecutable                                 // earliest executable
                        ? 0 
                        : settleFrom.utc().unix()
                ),
                description,                                                     // description
                connectedChainId
            );
            if(hash !== "0x0") {
                unwatchCreateError  = await watchEkrowEventAsync(connectedChainId, "EscrowError", createErrorHandler);
                unwatchCreateOk     = await watchEkrowEventAsync(connectedChainId, "EscrowCreated", createdSuccessHandler);
                setInfoDialogMessage("Escrow Creation SENT to blockchain. Wait for on-chain confirmation.");
                setIsInfoDialogOpen(true);
                await waitForTransaction({hash});
            }
            else {
                setSnackbarMessage("Transaction REJECTED or Failed.");
                setIsSnackbarOpen(true);
            }
            setIsInfoDialogOpen(false);
            setIsCreatingNewEscrowFlag(false);

        } catch (err: unknown) {
            console.log("Error POINT 5241.", err);
            setIsInfoDialogOpen(false);
        }
        finally {
            clearForm();
        };
    }

    function createdSuccessHandler(log: any) {
        if( Helpers.isSame(log[0].args._party1, connectedWallet) || 
            Helpers.isSame(log[0].args._party2, connectedWallet)
        ) {
            setCreatedEscrowId(log[0].args._escrowId);
            unwatchCreateOk?.();
        }
    }
    
    function createErrorHandler(log: any) {
        if( Helpers.isSame(
            log[0].args._origin, connectedWallet) || 
            Helpers.isSame(log[0].args._method, "create")
        ) {
            const errorCode = log[0].args._code;
            unwatchCreateError?.();
        }
    }



    function clearForm() {
        setParty1(connectedWallet as `0x${string}`);
        setParty2("");
        setToken1(null);
        setToken2(null);
        setToken1RequiredAmt(0.0);
        setToken2RequiredAmt(0.0);
        setDescription("");
        
        setIsSubmittedToChainFlag(false);
        setIsCreatingNewEscrowFlag(false);
    }

    

    // Onload ...
    useEffect(() => {
        setPageName?.("New Escrow");
    }, []);



    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h3 = isLg ? "h3" : (isMd ? "h4" : "h5");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");



    return (
        <main>
            <br />
            <Typography variant={h3} color="#404040">CREATE NEW ESCROW</Typography>
            <Typography variant={h4} color="#1976d2" style={{ display: 'inline' }}>
                <b>{connectedChainName}</b>
            </Typography> {" "}
            <Typography variant="body1" style={{ display: 'inline' }}>
                    | <b>FREE</b> TO USE{" "}
                    <Button variant = "contained"
                            color   = "success"
                            size    = {isMd ? "large" : "small"}
                    >Pay Only Gas</Button>
                </Typography>
            <br/><br />

            <NewEscrowForm
                tokenList                   = {tokenList} 
                connectedChainId            = {connectedChainId} 
                connectedWallet             = {connectedWallet as `0x${string}`}
                isCreatingNewEscrowFlag     = {isCreatingNewEscrowFlag}
                createButtonOnClickHandler  = {createNewEscrowHandler}
                createdEscrowId             = {createdEscrowId}

                party1                  = {party1}
                setParty1               = {setParty1}
                party2                  = {party2}
                setParty2               = {setParty2}
                token1                  = {token1}
                setToken1               = {setToken1}
                token2                  = {token2}
                setToken2               = {setToken2}
                token1RequiredQty       = {token1RequiredAmt}
                setToken1RequiredQty    = {setToken1RequiredAmt}
                token2RequiredQty       = {token2RequiredAmt}
                setToken2RequiredQty    = {setToken2RequiredAmt}
                party1LockRefund        = {party1LockRefund}
                setParty1LockRefund     = {setParty1LockRefund}
                party2LockRefund        = {party2LockRefund}
                setParty2LockRefund     = {setParty2LockRefund}
                isImmediatelyExec       = {isImmediatelyExecutable}
                setIsImmediatelyExec    = {setIsImmediatelyExecutable}
                settleFrom              = {settleFrom}
                setSettleFrom           = {setSettleFrom}
                expiry                  = {expiry}
                setExpiry               = {setExpiry}
                description             = {description}
                setDescription          = {setDescription}
            />


            {/* DIALOG for Wallet Approval Status */}
            <Dialog open={isInfoDialogOpen} onClose={() => handleInfoDialogCloseButton()}>
                <DialogTitle    fontSize={"11pt"} 
                                style={{ backgroundColor: '#1976d2', color: 'white' }}
                >
                    Wallet Approval (<ShortAddress addr={connectedWallet} />) {urlChainName}
                </DialogTitle>
                <DialogContent
                    style   = {{    display:        'flex', 
                                    flexDirection:  'column', 
                                    alignItems:     'center'
                    }}
                >
                    <Typography 
                        variant     = "body2"
                        color       = {"gray"} 
                        fontSize    = {"10pt"} 
                        width       = {"90%"} 
                        textAlign   = {"center"} 
                        padding     = {"10px"} 
                    >
                        {infoDialogMessage} {" "}
                        {
                            isInfoDialogShowSpinner
                            ? <CircularProgress size={16} />
                            : null 
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" 
                            sx={{textTransform: "none"}}
                            onClick = {() => handleInfoDialogCloseButton()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>



            {/* Snackbar Message */}
            <Snackbar
                open                = {isSnackbarOpen}
                autoHideDuration    = {snackbarShowDuration}
                onClose             = {handleCloseSnackbar}
                message             = {snackbarMessage}
                anchorOrigin        = {{horizontal: 'center', vertical: 'bottom'}}
                sx                  = {{backgroundColor: snackbarFlashColor}}
            />

        </main>
    );
};
export default ContentNewEscrow;