import  Decimal                 from "decimal.js";
import  { erc20ABI }            from "wagmi";
import  { 
  fetchBalance , readContract, 
  writeContract, watchContractEvent
}                               from '@wagmi/core';
import  { IEscrowInfo }         from "../../interfaces";
import  { IEscrowSource }       from "../../interfaces";
import  { getEskrowContractLatest, getEskrowContracts } from "../escrow";



export type FetchWalletBalanceResult = {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
};



export async function fetchWalletBalanceAsync(
  wallet:   `0x${string}`, 
  token:    `0x${string}`, 
  chainId:  number = 1
){
  let balance = {} as FetchWalletBalanceResult;
  try {
    balance = await fetchBalance({ chainId, address: wallet, token });
  } catch (err) {console.error(err);}
  return balance;
}


export async function fetchAllowanceAsync( 
  token:      `0x${string}`, 
  party:      `0x${string}`, 
  chainId:    number,
  contractId: number,
)
{
  let allowance: bigint = BigInt(0);
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const a = await readContract({
      address:      token,
      abi:          erc20ABI,
      chainId:      chainId,
      functionName: 'allowance',
      args:         [party, contract?.address ?? "0x0"],
    });
    allowance = a;
  }
  catch (err) {console.log(err);}
  return allowance;
}



export async function watchEkrowEventAsync( 
  chainId:    number, 
  eventName:  "EscrowCreated" | "EscrowError" | "EscrowFunded" | "EscrowIsFullyFunded" | "EscrowRefunded" | "EscrowRemoved" | "EscrowSettled" | "OwnershipTransferred" | undefined,
  eventHandler: (log: any) => void,
)
{
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = getEskrowContractLatest(contracts.contracts);

    const unwatch = watchContractEvent(
      {
        chainId:    chainId,
        address:    contract?.address,
        abi:        contracts.abi,
        eventName:  eventName,
      }, 
      eventHandler
    );  
    return unwatch;
  }
  catch (err) {
    console.log(err);
  }
  return null;
}



export async function callCreateEskrowAsync(
  party1: `0x${string}`, party2: `0x${string}`, 
  token1: `0x${string}`, token1RequiredQty: Decimal,
  token2: `0x${string}`, token2RequiredQty: Decimal,
  party1LockRefund: boolean,  party2LockRefund:   boolean,
  expiryEpoch:      bigint,   settleFromEpoch:    bigint,
  description:      string,
  chainId:          number
)
{
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = getEskrowContractLatest(contracts.contracts);

    const { hash }  = await writeContract({
        address:                contract?.address ?? "0x0",
        abi:                    contracts.abi,
        chainId:                chainId,
        functionName:           'create',
        args:   [   party1,
                    party2,
                    token1,
                    BigInt(token1RequiredQty.toFixed(0)),
                    token2,
                    BigInt(token2RequiredQty.toFixed(0)),
                    party1LockRefund, party2LockRefund,
                    expiryEpoch,
                    settleFromEpoch,
                    description
                ],
    });
    return hash;
  }
  catch (err) {console.log(err)}
  finally {}
  return "0x0";
}



export async function callDepositErc20Async( 
  escrowId:   `0x${string}`, 
  token:      `0x${string}`, 
  quantity:   bigint, 
  chainId:    number,
  contractId: number, 
  errorHandler?: (err: Error) => void,
) {
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const { hash }  = await writeContract({
      address:                contract?.address ?? "0x0",
      abi:                    contracts.abi,
      chainId:                chainId,
      functionName:           'depositErc20',
      args:   [   escrowId,
                  token,
                  quantity,
              ],
    });
    return hash;
  }
  catch (err) {
    console.log(err);
    errorHandler?.(err as Error);
  }
  finally {}
  return "0x0";
}



export async function callSettleAsync(escrowId: `0x${string}`, chainId: number, contractId: number) {
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const { hash }  = await writeContract({
        address:                contract?.address ?? "0x0",
        abi:                    contracts.abi,
        chainId:                chainId,
        functionName:           'settle',
        args:   [escrowId],
    });
    return hash;
  }
  catch (err) {console.log(err)}
  finally {}
  return "0x0";
}



export async function callRefundAsync(escrowId: `0x${string}`, chainId: number, contractId: number) {
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const { hash }  = await writeContract({
        address:                contract?.address ?? "0x0",
        abi:                    contracts.abi,
        chainId:                chainId,
        functionName:           'refund',
        args:   [escrowId],
    });
    return hash;
  }
  catch (err) {console.log(err)}
  finally {}
  return "0x0";
}



export async function callRemoveAsync(escrowId: `0x${string}`, chainId: number, contractId: number) {
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const { hash }  = await writeContract({
        address:                contract?.address ?? "0x0",
        abi:                    contracts.abi,
        chainId:                chainId,
        functionName:           'remove',
        args:   [escrowId],
    });
    return hash;
  }
  catch (err) {console.log(err)}
  finally {}
  return "0x0";
}



export async function callSetAllowanceAsync( token: `0x${string}`, quantity: bigint, chainId: number, contractId: number )
{
  try {
    const contracts = getEskrowContracts(chainId);
    const contract  = contracts.contracts.find((c) => c.id == contractId);

    const { hash }  = await writeContract({
      address:        token,
      abi:            erc20ABI,
      chainId:        chainId,
      functionName:   'approve',
      args:           [   contract?.address ?? "0x0",
                          quantity,
                      ],
    });
    return hash;
  }
  catch (err) {console.log(err);}
  finally {}
  return "0x0";
}



export async function fetchEscrowIdsByParty(
  partyAddress: `0x${string}`, 
  chainId: number
): Promise<IEscrowSource[]> 
{
  try {
    const contracts = getEskrowContracts(chainId);
    if (!contracts) return [];

    const promises = contracts.contracts.map((c) =>
    {
      return readContract({
        address:      c.address,
        abi:          contracts.abi,
        chainId:      chainId,
        functionName: 'getEscrowIdsByParty',
        args:         [partyAddress],
      }).then((escrowIds) => {
        return escrowIds.map((escrowId) => ({
            escrowId:   escrowId,
            chainId:    chainId,
            contractId: c.id,
        }));
      });
    });

    const escrowIds:IEscrowSource[] = (await Promise.all(promises)).flat();
    return escrowIds;
  } catch (err) {
    console.log(err);
    return [];
  }
}



export async function fetchEscrowDetailsByIdAsync( escrowSource: IEscrowSource ) {
  let details: IEscrowInfo | null = null;
  
  try {
    const contracts = getEskrowContracts(escrowSource.chainId);
    const contract = contracts.contracts.find((c) => c.id == escrowSource.contractId);
    if( !contract || !contract ) {return null;}

    const data       = await readContract({
      address:      contract.address,
      abi:          contracts.abi,
      chainId:      escrowSource.chainId,
      functionName: 'getEscrowExt',
      args:         [escrowSource.escrowId],
    });
    details = {...data, contractId: escrowSource.contractId};
  } catch (err) {console.log(err)}
  finally {}
  return details;
}
