import * as React       from 'react';
import  {
  Typography, TextField, Autocomplete 
}                       from '@mui/material';
import  { ITokenInfo }  from "../../interfaces/ITokenInfo";
import  { isAddress, fetchTokenDetails, isSame }  from '../../helpers';



type ComponentProps = {
    tokenList:  ITokenInfo[],
    chainId:    number | null,
    onChange:   (value: ITokenInfo | null) => void,
    label?:     string | null,
    token:      ITokenInfo | null,
    setToken:   (value: ITokenInfo | null) => void,
};
const TokenSelector: React.FC<ComponentProps> = ({ 
    tokenList, chainId, onChange, label, 
    token:    selectedToken, 
    setToken: setSelectedToken,
}) =>  {

  const filteredOptions       = tokenList.filter( (t: ITokenInfo) => 
                                selectedToken ? t.symbol.startsWith(selectedToken.symbol) : true );
  const selectedOption        = filteredOptions.find( (t: ITokenInfo) =>
                                t.symbol === selectedToken?.symbol);
  const isOptionEqualToValue  = (option: ITokenInfo, value: ITokenInfo | null) => 
                                isSame(option.address, value?.address);



  return (
    <>
      <Autocomplete
        fullWidth
        value       =   {selectedOption ?? null}
        onChange    =   {(event, value: ITokenInfo | null) => {
                            if( value?.symbol === "ADD"
                                && isAddress(value?.name)
                                && value.address.toLowerCase() === value.name.toLowerCase()
                                && !tokenList.find( (token: ITokenInfo) =>
                                token.address === value.address )){
                                fetchTokenDetails(value.address, chainId?chainId:0)
                                    .then((t) => {
                                        tokenList.push(t); 
                                        onChange(t); 
                                        setSelectedToken(t);
                                    });
                                return null;
                            }
                            else {
                                onChange(value);
                                setSelectedToken(value);
                                return null;
                            };
                        }}
        options               = {tokenList}
        filterOptions         = {(options: ITokenInfo[], params) => {
                                  const { inputValue } = params;
                                  const f = tokenList.filter(
                                    (token: ITokenInfo) => {
                                      return (
                                        inputValue ? 
                                        (token.symbol.toLowerCase().includes(inputValue.trim().toLowerCase()) 
                                          || isSame(token.address, inputValue.trim())) 
                                        : true);
                                    }
                                  );
                                  if(f.length === 0 && isAddress(inputValue)) {
                                    f.push( {
                                              symbol  : "ADD", 
                                              logoURI : "", 
                                              name    : inputValue,
                                              address : inputValue.toLowerCase().trim(),
                                    } as ITokenInfo );
                                  }
                                  return f;
                                }}
        getOptionLabel        = {(option: ITokenInfo) => option.symbol}
        isOptionEqualToValue  = {isOptionEqualToValue}
        renderOption          = {(props, option: ITokenInfo) => (
                                  <li {...props}>
                                    {
                                      option.logoURI !== "" ? (
                                        <div>
                                          <img  src   = {option.logoURI}
                                                alt   = {option.symbol + " Icon"}
                                                width = {26}
                                            />
                                        </div>
                                      ) : ""
                                    }
                                    <Typography variant="subtitle2" marginLeft="5px">{option.symbol}</Typography>
                                    {
                                      option.name !== ""
                                      ? <Typography variant="caption" marginLeft="5px">({option.name})</Typography>
                                      : ""
                                    }
                                  </li>
                                )}
        renderInput           = {(params) => 
                                  <TextField {...params} 
                                    label       = { (tokenList.length !== 0) ? ( label ? label : "Select Token") : "No tokens, check connection." } 
                                    error       = { (selectedToken === null) ? true : false}
                                    helperText  = { (selectedToken === null) ? "* Mandatory" : "" }
                                    sx          = {{width: '100%'}}
                                  />
                                }
      />
    </>
  );
  
};

export default TokenSelector;