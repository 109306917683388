import  axios           from 'axios';
import  dayjs           from 'dayjs';
import  utc             from 'dayjs/plugin/utc';
import  Decimal         from "decimal.js";
import  { Chain }       from 'wagmi/chains';
import  { ITokenInfo }  from '../../interfaces/ITokenInfo';
import  { fetchToken }  from '@wagmi/core'
import { extendedChainInfos } from '../chains';



export async function wait(ms: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export function isSame( 
    address1:   string | undefined, 
    address2:   string | undefined) 
{
    if( address1?.toLowerCase() === address2?.toLowerCase())
        return true;
    return false;
}

export const getDateTimeFromGmtEpoch = (dateEpochInGmt: bigint | undefined): dayjs.Dayjs => {
    dayjs.extend(utc);

    if( dateEpochInGmt === undefined ) {
        dateEpochInGmt = BigInt(dayjs().utc().unix())
    };
    const d = dayjs.unix(Number(dateEpochInGmt))
                .utcOffset(-(new Date())
                .getTimezoneOffset());
    return d;
}



// function to test if a string is a wallet address
export function isAddress(value: string | undefined = "") {
    const regex = /^0x[0-9a-fA-F]{40}$/;
    return regex.test(value);
}



// function to test if a string is a transaction address
export function isTransaction(value: string | undefined = "") {
    const regex = /^0x[0-9a-fA-F]{64}$/;
    return regex.test(value);
}



// 
export const defaultAddressIfInvalid = (addressString: string | undefined) => {
    const value 
        = (isAddress(addressString) ? addressString : "0x0") as `0x${string}`;
    return value;
}



// 
export function getRandomBetween(min: number, max: number): number {
    // Calculate the range of possible values
    const range = max - min + 1;
  
    // Generate a random value within the range
    const randomValue = Math.floor(Math.random() * range) + min;
  
    return randomValue;
  }

// function to randomly return one of the string
export function randomOne(values: string[]) {
    const element = getRandomBetween( 0, values.length -1 );
    return values[element];
}

//
export function parseUrl( location: Location)
{
    const urlSplit = window.location.href.split("?");
    const fullPathName = urlSplit[0];
    const searchParams = new URLSearchParams(urlSplit[1]);

    return {fullPathName, pathName: location.pathname, searchParams};
}

//
export function buildUrl( fullPathName: string, parameters: [string, string][])
{
    const paramString = (new URLSearchParams(parameters)).toString();
    return `${fullPathName}${paramString ? "?" : ""}${paramString}`;
}

//
export function getUrlParameter(key: string, defaultValue?: any ) {
    const currentUrl    = new URL(window.location.href);
    const value         = currentUrl.searchParams.get(key) ?? defaultValue;
    return value;
};



//
let lastFetchTokens = dayjs().add(-10, "seconds");
export async function fetchTokensDebouncedAsync(
    chainId:        number | undefined, 
    setTokenList:   (newValue: ITokenInfo[]) => void)
{
    try {
        if( dayjs().diff(lastFetchTokens, "seconds") > -1) {
            lastFetchTokens = dayjs();
            var list: ITokenInfo[] = 
                // (await axios.get('https://gateway.ipfs.io/ipns/tokens.uniswap.org')).data.tokens;
                (await axios.get('/tokenlist.json')).data.tokens;
                if(chainId) {
                    list = list.filter((token) => token.chainId === chainId);
                    setTokenList(list);
                }
        }
    }
    catch (error) {};
};


  // Fetch the ITokenInfo of a token address
export async function fetchTokenDetails(tokenAddress: `0x${string}`, chainId: number) 
{
    const details = await fetchToken({ address: tokenAddress.toLowerCase() as `0x${string}`, chainId: chainId });
    const tokenDetails: ITokenInfo  = {
        name:       details.name,
        chainId:    chainId,
        logoURI:    "",
        address:    details.address,
        symbol:     details.symbol,
        decimals:   details.decimals,
        tags:       [],
    };
    return tokenDetails;
};



export function findTokenFromList( tokenAddress: `0x${string}` | undefined, tokenList: ITokenInfo[] )
{
    if(tokenAddress === undefined)
        return undefined;
    const result = tokenList.find( (t) => {
        return t.address.toLowerCase() === tokenAddress.toLowerCase()} );
    return result;
}



export function amountToQuantity(amount: number | string | undefined = 0, decimals: number | undefined = 1) {
    const result = toDecimal(amount).times(powToDecimals(decimals));
    return result;
}

export function quantityToAmount(qty: Decimal | undefined = new Decimal(0), decimals: number | undefined = 1) {
    const result = Number(qty.div(powToDecimals(decimals)));
    return result;
}

export function gt(left: bigint | undefined, right: bigint | undefined) {
    if((left === undefined) || (right === undefined)) {return (undefined);}
    return ((left ?? BigInt(0)) > (right ?? BigInt(0)));
}
export function goet(left: bigint | undefined, right: bigint | undefined) {
    if((left === undefined) || (right === undefined)) {return (undefined);}
    return ((left ?? BigInt(0)) >= (right ?? BigInt(0)));
}
export function lt(left: bigint | undefined, right: bigint | undefined) {
    if((left === undefined) || (right === undefined)) {return (undefined);}
    return ((left ?? BigInt(0)) < (right ?? BigInt(0)));
}
export function loet(left: bigint | undefined, right: bigint | undefined) {
    if((left === undefined) || (right === undefined)) {return (undefined);}
    return ((left ?? BigInt(0)) <= (right ?? BigInt(0)));
}



export function shortenAddress( address: string | undefined = "")
{
    address = address.trim();
    if(isAddress(address))
    {
        const addressP1 = address.slice(0, 6);
        //const addressP2 = address.slice(6, 32);
        const addressP3 = address.slice(-4);
        return (addressP1 + " ... " + addressP3);
    }
    return undefined;
}



export function shortenTxnId( txnId: string | undefined = "")
{
    txnId = txnId.trim();
    if(isTransaction(txnId))
    {
        const txnP1 = txnId.slice(0, 4);
        const txnP3 = txnId.slice(-6);
        return (txnP1 + " ... " + txnP3);
    }
    return undefined;
}



// export function counterparty( 
//     connectedWallet: `0x${string}`, 
//     party1: `0x${string}`, 
//     party2: `0x${string}` 
// ){
//     return (connectedWallet === party1 ? party2 : party1);
// }


export function getPartyOfInterestTokenNQty(
    partyOfInterest: `0x${string}`,
    party1:     `0x${string}`,
    token1:     ITokenInfo,
    token1Qty:  bigint,
    party2:     `0x${string}`,
    token2:     ITokenInfo,
    token2Qty:  bigint,
) {
    let counterparty: `0x${string}`     = "0x0";
    let token:  ITokenInfo              = token1;
    let qty:    bigint                  = BigInt(0);
    let counterpartyToken: ITokenInfo   = token2;
    let counterpartyQty: bigint         = BigInt(0);

    if( partyOfInterest === party1) {
        token               = token1;
        qty                 = token1Qty;
        counterparty        = party2;
        counterpartyToken   = token2;
        counterpartyQty     = token2Qty;
    }
    if( partyOfInterest === party2) {
        token               = token2;
        qty                 = token2Qty;
        counterparty        = party1;
        counterpartyToken   = token1;
        counterpartyQty     = token1Qty;
    }
    return { partyOfInterest, token, qty, counterparty, counterpartyToken, counterpartyQty };
}

export function isIn( text: string | null, group: string[] = []) {
    const found = group.find((t) => t.trim().toLowerCase() === text?.trim().toLowerCase());
    return (found !== undefined);
}


export function toDecimal(n: number | bigint | string) {
    return (new Decimal(n.toString()));
}

export function toBigInt(dec: Decimal | number | string) {
    return BigInt( dec.toString() );
}


export function powToDecimals( decimals: number) {
    const d = (new Decimal(10)).pow(decimals);
    return d;
}



export function setPageMeta(
    {title, description, canonical,}: {
        title?:         string;
        description?:   string;
        canonical?:     string;
}) {
    if (title) {document.title = title;}
    if (description) {
        const el = document.querySelector("meta[name='description']");
        if (el) {el.setAttribute("content", description);}
    }
    if (canonical) {
        const el = document.querySelector("link[rel='canonical']");
        if (el) {el.setAttribute("href", canonical);}
    }
}


export function getExtendedChainInfo( chain: Chain)
{
    return extendedChainInfos.find( (e) => e.chainId === chain.id);
}