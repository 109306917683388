import  React                           from "react";
import  { useState, useEffect, }        from "react";
import  { 
    Button, Typography, Grid, Box,
    Table, TableBody, TableContainer, 
    Paper, Tab, Avatar, Link
}                                       from '@mui/material';
import {TabPanel, TabList, TabContext}  from "@mui/lab";

import  dayjs                   from 'dayjs';
import  utc                     from 'dayjs/plugin/utc';

import  { watchNetwork }        from '@wagmi/core';
import  { watchAccount }        from '@wagmi/core';

import  { fetchEscrowIdsByParty, fetchEscrowDetailsByIdAsync }
                                from '../../helpers/smartContracts/SCHelpers';
import  { ITokenInfo }          from "../../interfaces";  
import  { IEscrowInfo }         from "../../interfaces";
import  { IEscrowSource }       from "../../interfaces";

import  { 
    escrowStatusIsActive, escrowStatusIsFullyFunded, 
    sortEscrowsByEarliestDescThenExpiryDesc 
}                               from "../../helpers/escrow/EscrowHelpers";
import  * as Helpers            from "../../helpers/general/Helpers";
import  * as Theme              from "../EskrowTheme";
import  { useWeb3ClientLogic }  from "../../hooks";

import  EscrowHeader        from "./EscrowHeader";
import  EscrowList          from "./EscrowList";
import  RotatingUseCases    from "./RotatingUseCases";

import '../myCss.css';



type ComponentProps = {
    setPageName?: (newValue: string) => void;
};
const ContentApp: React.FC<ComponentProps> = ({ setPageName }) => {
    dayjs.extend(utc);

    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();
   
    

    const [tabId, setTabId]             = useState("1");
    const [escrowIds, setEscrowIds]     = useState<IEscrowSource[]>([]);

    const {
        supportedChains, connectedChainId, 
        connectedWallet, urlChainName, urlChainId,
        tokenList, setTokenList, canonical
    } = useWeb3ClientLogic("app");

    const unwatchNetwork = watchNetwork((network) => {setEscrowIds([])});
    const unwatchAccount = watchAccount((account) => {setEscrowIds([])});

    Helpers.setPageMeta({
        title:          `Eskrow dApp | ${urlChainName}`,
        description:    "Escrow dApp | Decentralized escrow dApp for ERC-20 tokens on the " + urlChainName + " blockchain.",
        canonical:      location.href,
    });


    // =====> MAIN PAGE LOADING LOGIC
    useEffect(() => {
        const refreshList = setInterval( () => loadList(), 20000 );
        return () => {clearInterval(refreshList)};
    }, []);
    function loadList() {
        if( connectedWallet === "0x0") {setEscrowIds([]);}
        else {
            try {
                fetchEscrowIdsByParty(  
                    connectedWallet ?? "0x0", 
                    connectedChainId)
                .then((escrowNsource) => {
                        setEscrowIds(escrowNsource);
                    });
            } catch (err) {console.log(err);}
            finally {}
        }
    }



    // 1. Load all the escrowIds for the connectedWallet.
    useEffect( () => {
        loadList();
    }, [connectedWallet, connectedChainId])

    // 2. Load all the escrowDetails.
    const [escrowDetails, setEscrowDetails] = useState<IEscrowInfo[]>([]);
    const openEscrows       = escrowDetails.filter((escrow) => escrowStatusIsActive(escrow) && !(escrowStatusIsFullyFunded(escrow) && (escrow.settleFromEpoch > (dayjs().utc().unix()))) );
    const upcomingEscrows   = escrowDetails.filter((escrow) => escrowStatusIsActive(escrow) && escrowStatusIsFullyFunded(escrow) && (escrow.settleFromEpoch > (dayjs().utc().unix())) );
    const expiredEscrows    = escrowDetails.filter((escrow) => escrow.isExpired || escrow.isFullyWithdrawn);
    useEffect(() => {
        const fetchEsrowIdsForConnectedWallet = async (chainId: number, tokenList: ITokenInfo[]) => {
            try {
                const promises  = escrowIds.map((item) => fetchEscrowDetailsByIdAsync(item));
                const list      = await Promise.all(promises);
                const sorted    = sortEscrowsByEarliestDescThenExpiryDesc(list);
                setEscrowDetails(sorted);
            } catch (error) {
                console.log("Error POINT 2211.", error);
                setEscrowDetails([]);
            }
        };
        fetchEsrowIdsForConnectedWallet(connectedChainId, tokenList);
    }, [escrowIds]);

    // 3. Identify all unknown tokens in the escrowDetails
    const [unknownTokens, setUnknownTokens] = useState<`0x${string}`[]>([]);
    useEffect(() => {
        let missingTokens: `0x${string}`[] = [];
        escrowDetails.forEach((c) => {
            if( (tokenList.find((t) => t.address.toLowerCase() === c.token1.toLowerCase()) === undefined) ) {
                missingTokens.push(c.token1);
            }
        });
        escrowDetails.forEach((c) => {
            if( (tokenList.find((t) => t.address.toLowerCase() === c.token2.toLowerCase()) === undefined) ) {
                missingTokens.push(c.token2);
            }
        });
        const unique    = new Set(missingTokens);
        missingTokens   = Array.from(unique);
        setUnknownTokens(missingTokens);
    }, [escrowDetails]);

    // 4. Download missing tokens details
    useEffect(() => {
        async function fetchMissingTokens( tokens: `0x${string}`[], chainId: number )
        {
            const promises      = tokens.map(addr => Helpers.fetchTokenDetails(addr, chainId));
            const newDetails    = await Promise.all(promises);
            let newList         = [...newDetails, ...tokenList];

            const unique    = new Set(newList);
            newList         = Array.from(unique);
            setTokenList(newList);
        }
        fetchMissingTokens(unknownTokens, connectedChainId);
    }, [unknownTokens]);


    
    // Onload ...
    // useEffect(() => {
    //     setPageName?.("App");
    // }, []);



    return (
        <>
            {/* ----- MAIN Display area -----*/}
            <br />
            <Grid container sx={{color: "#606060"}}>
                <Grid item xs={12}>
                    <Box sx={{ border: '5px solid #1976d2', margin: isMd ? "10px" : "1px", padding: isMd ? "10px" : "2px"}}>
                        <Grid container maxWidth={"xl"} >

                            {/* ----- Connected / Switch Chain Box ----- */}
                            <Grid item xs={12} md={3} >
                                <Box sx={{
                                            border:             '2px solid #1976d2', 
                                            margin:             "0px", 
                                            padding:            isMd ? "10px": "2px", 
                                            backgroundColor:    "#f5f5f5"
                                        }}
                                >
                                    <Typography variant="caption"><b>Switch Chain</b></Typography><br />
                                    {
                                        [true, false].map( (isEnabled) => supportedChains
                                            .filter((c) => c.isEnabled === isEnabled)
                                            .map((chain, index) => {
                                        const url = Helpers.buildUrl("/app", [["chainId", chain.chainId.toString()]]);
                                        return (
                                            chain.isEnabled
                                            ?
                                                <Link href={url} key={chain.chainId}>
                                                    <Button key     = {index} 
                                                            variant = "outlined"
                                                            disabled= { !chain.isEnabled}
                                                            sx      = {{    
                                                                fontSize:       "7pt", 
                                                                padding:        "5px", 
                                                                margin:         "2px", 
                                                                textTransform:  "none", 
                                                                border:          chain.chainId === connectedChainId ? "3px solid" : "",
                                                                color:           chain.isTestNet ? "#303030" : "#1976d2",
                                                                backgroundColor: chain.isTestNet ? "#a0a0a0" : "#f0f0f0",
                                                            }}
                                                            startIcon   = {<Avatar src={chain.iconUrl} alt={chain.chainName} style={{ width: '14px', height: '14px' }}/>}
                                                        >
                                                            {chain.chainName}
                                                    </Button>
                                                </Link>
                                            :
                                                <Button key     = {index} 
                                                    variant = "outlined"
                                                    disabled= { !chain.isEnabled}
                                                    sx      = {{    
                                                        fontSize:       "7pt", 
                                                        padding:        "5px", 
                                                        margin:         "2px", 
                                                        textTransform:  "none", 
                                                        border:          chain.chainId === connectedChainId ? "3px solid" : "",
                                                        color:           chain.isTestNet ? "#303030" : "#1976d2",
                                                        backgroundColor: chain.isTestNet ? "#a0a0a0" : "#f0f0f0",
                                                    }}
                                                    startIcon   = {<Avatar src={chain.iconUrl} alt={chain.chainName} style={{ width: '14px', height: '14px' }}/>}
                                                >
                                                    {chain.chainName}
                                                </Button>
                                        )}))}
                                </Box>
                            </Grid>


                        {/* ----- Top Info/Display Section */}
                        <Grid item xs={12} md={9} >
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <RotatingUseCases />
                                    </Grid>

                                    <Grid item xs={12} md={4} textAlign={isMd ? "right" : "center"}>
                                        <Link href={Helpers.buildUrl("/newEscrow", [["chainId", connectedChainId.toString()]])}>
                                            <Button variant="contained" size="large">
                                                Create Escrow
                                            </Button>
                                        </Link>
                                        <br />
                                        <Typography variant="caption">
                                            (in {urlChainName})
                                        </Typography>

                                        <Typography variant="body2" color="#606060">
                                            <b>FREE</b> To Use (
                                            <Button size= "small"
                                                variant = "contained"
                                                color   = "success"
                                                sx      = {{padding: "2px", margin: "2px"}}
                                            >Pay Only Gas</Button>)
                                            {isMd ? (null) : <><br /><br /></> }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>



                        {/* ESCROW LIST SECTION */}
                        <TabContext value={tabId}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, id) => setTabId(id)} centered>
                                    <Tab label={`Active (${openEscrows.length})`}       value= "1" sx={{fontSize: isMd ? "10pt" : "9pt"}} />
                                    <Tab label={`Upcoming (${upcomingEscrows.length})`} value= "2" sx={{fontSize: isMd ? "10pt" : "9pt"}} />
                                    <Tab label={`History (${expiredEscrows.length})`}   value= "3" sx={{fontSize: isMd ? "10pt" : "9pt"}} />
                                </TabList>
                            </Box>



                            {/* Active / Effective Panel */}
                            <TabPanel value="1" sx={{margin: "0px", padding:"0px"}}>
                                <Box sx={{margin: "0px", padding: "0px"}}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <EscrowHeader connectedWallet={connectedWallet} />
                                            <TableBody>
                                            <EscrowList 
                                                    escrowDetails   = {openEscrows} 
                                                    connectedWallet = {connectedWallet} 
                                                    connectedChainId= {connectedChainId} 
                                                    tokenList       = {tokenList} 
                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </TabPanel>



                            {/* Upcoming Panel */}
                            <TabPanel value="2" sx={{margin: "0px", padding:"0px"}}>
                                <Box sx={{margin: "0px", padding: "0px"}}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <EscrowHeader connectedWallet={connectedWallet} />
                                            <TableBody>
                                                <EscrowList 
                                                    escrowDetails   = {upcomingEscrows} 
                                                    connectedWallet = {connectedWallet} 
                                                    connectedChainId= {connectedChainId} 
                                                    tokenList       = {tokenList}
                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </TabPanel>



                            {/* History Panel */}
                            <TabPanel value="3" sx={{margin: "0px", padding:"0px"}}>
                                <Box sx={{margin: "0px", padding: "0px"}}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <EscrowHeader connectedWallet={connectedWallet} />
                                            <TableBody>
                                                <EscrowList 
                                                    escrowDetails   = {expiredEscrows} 
                                                    connectedWallet = {connectedWallet} 
                                                    connectedChainId= {connectedChainId} 
                                                    tokenList       = {tokenList} 
                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </TabPanel>
                        </TabContext>

                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ContentApp;