import  React from 'react';
import  'prismjs';
import  'prismjs/components/prism-solidity';
import  { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import  { solarizedlight, dark, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SolidityCodeViewer: React.FC<{ code: string }> = ({ code }) => {
  return (
    <SyntaxHighlighter language="solidity" style={a11yDark} showLineNumbers={true}>
      {code}
    </SyntaxHighlighter>
  );
};

export default SolidityCodeViewer;