import { useEffect, useState }      from 'react'
import  {
    AppBar, Typography, Button, Link, Grid, Tooltip,
}                                   from '@mui/material';
import  LocalGasStationIcon         from '@mui/icons-material/LocalGasStation';

import { useAccount, useNetwork }   from 'wagmi';
import { fetchFeeData }             from '@wagmi/core'

import  { useWeb3ClientLogic }      from "../hooks";
import  { buildUrl }                from '../helpers';

import  * as Theme                  from "./EskrowTheme";
import  LogoEskrow                  from "../assets/logo_Eskrow.png";



type ComponentProps = {
    pageTitle: string,
};
const TitleBar: React.FC<ComponentProps> = ({ pageTitle, }) => {
    Theme.createEskrowTheme();
    const { theme: isSm, }                  = Theme.useEskrowTheme();
    const { supportedChains, urlChainId, }  = useWeb3ClientLogic("");


    const { chain }             = useNetwork();
    const { address }           = useAccount();
    const [gasFee, setGasFee]   = useState<string>("");
    const urlHome               = buildUrl( "/", []);



    // ----------
    useEffect(() => {
        // Update the Gas Fee display at top of Title bar
        function updateGasFee() {
            fetchFeeData({formatUnits: 'gwei',}).then((data) => {
                var gasFormatted:number = parseFloat(data?.formatted.gasPrice as string);
                setGasFee(gasFormatted.toFixed(1));
            });
        };

        // Fetch gas fee on start of page.
        updateGasFee();

        // Then setup auto-refresh of GasFee every 30 seconds
        const updateGasTime = setInterval( () => updateGasFee(), 30000 );
        return () => {clearInterval(updateGasTime)};
    }, []);



    return (
        <AppBar position="sticky">
            <Grid container spacing={2} maxWidth={"xl"} sx={{ alignItems: "flex-end", padding: "5px" }}>
                {/* <Grid item xs={1} md={1}>
                    <MenuPanel supportedChains={supportedChains}/>
                </Grid> */}
                <Grid item xs={12} md={7}>
                    <Link href={urlHome} style={{ textDecoration: "none", color: "white" }}>
                        <img src={LogoEskrow} width={isSm ? 36 : 26} alt="Eskrow Logo" />
                        <Typography variant={isSm ? "h3" : "body1"} sx={{ marginLeft: 1 }} display={"inline"}>
                            eskrow
                        </Typography>
                        <Typography variant={isSm ? "h6" : "body2"} sx={{ marginLeft: 1 }} display={"inline"}>
                            {pageTitle ? (" | " + pageTitle) : ""}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} md={5}
                    alignItems  = "bottom"
                    sx={{
                        backgroundColor:    "cornflowerblue",
                        borderRadius:       '10px',
                        display:            'flex',
                        alignItems:         'center',   // Vertically center
                        justifyContent:     'flex-end', // Right-align the content
                      }}
                >
                    <Tooltip title={chain?.name}>
                        <div style={{ display: 'flex', }}>
                            <Button
                                variant = "outlined"
                                size    = "small"
                                sx      = {{ textTransform: 'none', color: 'white' }}
                            >
                                <LocalGasStationIcon />
                                    {(address && (" " + gasFee + " Gwei "))}
                            </Button>
                            <w3m-button label="Connect Wallet" />
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
      </AppBar>
    );
}
export default TitleBar;