import  { useState }                from "react";
import  { waitForTransaction }      from '@wagmi/core';
import  Decimal                     from 'decimal.js';

import  * as Helpers                from "../../helpers/general/Helpers";
import { ITokenInfo }               from "../../interfaces/ITokenInfo";
import { IEscrowInfo }              from "../../interfaces";
import { 
    callDepositErc20Async, callRefundAsync, callRemoveAsync, 
    callSetAllowanceAsync, callSettleAsync, 
    fetchEscrowDetailsByIdAsync, 
}                                   from "../../helpers";



export interface IDepositProcess {
    message:        string,
    isApproving:    boolean,
    isFunding:      boolean,
}

export function IDepositProcess( message: string, isApproving: boolean, isFunding: boolean) {
    const dp: IDepositProcess = {message: message, isApproving: isApproving, isFunding: isFunding};
    return dp;
}



export async function settleEscrowAsync(escrowId: `0x${string}`, chainId: number, contractId: number,
    setIsSnackbarOpen?: (value: boolean) => void,
    setSnackbarMessage?:(value: string) => void,
) {
    try {
        const hash = await callSettleAsync(escrowId, chainId, contractId);
        if( hash !== "0x0" )
        {
            await Helpers.wait(1500);
            const aData = await waitForTransaction({ hash, chainId, timeout: 60000 });
        }
        else {
            setSnackbarMessage?.("Transaction REJECTED or Failed.");
            setIsSnackbarOpen?.(true);
        }
    } catch (err) {
        console.log("Error POINT 5177.", err);
    }
    finally {
    };
}



export async function refundEscrowAsync(escrowId: `0x${string}`, chainId: number, contractId: number,
    setIsSnackbarOpen?: (value: boolean) => void,
    setSnackbarMessage?:(value: string) => void,
) {
    try {
        const hash = await callRefundAsync(escrowId, chainId, contractId);
        if( hash !== "0x0" )
        {
            await Helpers.wait(1500);
            const aData = await waitForTransaction({ hash, chainId, timeout: 60000 });
        }
        else {
            setSnackbarMessage?.("Transaction REJECTED or Failed.");
            setIsSnackbarOpen?.(true);
        }
    } catch (err) {
        console.log("Error POINT 4218.", err);
    }
    finally {
    };
}



export async function removeEscrowAsync(escrowId: `0x${string}`, chainId: number, contractId: number,
    setIsSnackbarOpen?: (value: boolean) => void,
    setSnackbarMessage?:(value: string) => void,
) {
    try {
        const hash = await callRemoveAsync(escrowId, chainId, contractId);
        if( hash !== "0x0" )
        {
            await Helpers.wait(1500);
            const aData = await waitForTransaction({ hash, chainId, timeout: 60000 });
        }
        else {
            setSnackbarMessage?.("Transaction REJECTED or Failed.");
            setIsSnackbarOpen?.(true);
        }
    } catch (err) {
        console.log("Error POINT 4218.", err);
    }
    finally {
    };
}



type UseDepositProps = {
    escrowId:               `0x${string}`,
    chainId:                number,
    contractId:             number,
    setIsDepositDialogOpen: (value: boolean) => void,
    setEscrow:              (value: IEscrowInfo | null) => void,
    setIsSnackbarOpen:      (value: boolean) => void,
    setSnackbarMessage:     (value: string) => void,

};
export function useDeposit(
    {
        escrowId, chainId, contractId, setIsDepositDialogOpen, setEscrow, 
        setIsSnackbarOpen, setSnackbarMessage
    }: UseDepositProps
) {
    const [depositProcess, setDepositProcess] = useState(IDepositProcess("", false, false));

    // -----
    // Request for Spend Approval
    async function approveAmountAsync(
        party:  `0x${string}`, 
        token:  ITokenInfo, 
        amount: number, 
        setIsRequestingAllowance?: (value: boolean) => void,
    )
    {
        try {
            setIsRequestingAllowance?.(true);
            const quantity: Decimal = (new Decimal(amount)).times( (new Decimal(10).pow( token.decimals)));
            
            setDepositProcess(
                IDepositProcess( `Please Set & Approve SPEND of ${token.symbol} ${amount.toString()} in wallet ...`, 
                true, false));
            const aHash = await callSetAllowanceAsync(token.address, Helpers.toBigInt(quantity), chainId, contractId);
    
            if( aHash !== "0x0" )
            {
                setDepositProcess(
                    IDepositProcess( `Please wait for Spend Approval to be confirmed on-chain ...`, 
                    true, false));
                await Helpers.wait(1500);
                const aData = await waitForTransaction({ hash: aHash, chainId, timeout: 60000 });
            }
            else {
                setSnackbarMessage("Transaction REJECTED or Failed.");
                setIsSnackbarOpen(true);
            }
        } catch (err) {
            console.log("Error POINT 4216.", err)
    }
        finally {
            setDepositProcess(IDepositProcess( "", false, false));
            setIsRequestingAllowance?.(false);
        };
    }



    // -----
    // Request to Fund/Deposit Tokens
    async function depositEscrowAsync(
        party:          `0x${string}`, 
        token:          ITokenInfo, 
        amount:         number, 
        contractId:     number,
        errorHandler?: (err: Error) => void,
    ) {
        try {
            const quantity: Decimal = (new Decimal(amount)).times( (new Decimal(10).pow( token.decimals)));
            
            setDepositProcess(
                IDepositProcess( `Please approve FUNDING in wallet ...`, 
                false, true));
            const dHash = await callDepositErc20Async(
                escrowId, token.address, Helpers.toBigInt(quantity), 
                chainId, contractId, errorHandler);
            
            if(dHash !== "0x0")
            {
                setDepositProcess(
                    IDepositProcess( `Wait for FUNDING to be confirmed on-chain ...`, 
                    false, true));
                await Helpers.wait(1500);
                const dData = await waitForTransaction({hash: dHash, chainId, timeout: 60000});
            }
            else {
                setSnackbarMessage("Transaction REJECTED or Failed.");
                setIsSnackbarOpen(true);
            }
            setIsDepositDialogOpen(false);
    
        } catch (err) {
            console.log("Error POINT 4217.", err)
        }
        finally {
            setDepositProcess(IDepositProcess( "", false, false));
            fetchEscrowDetailsByIdAsync({escrowId, chainId, contractId})
                .then((e) => {setEscrow(e)});
        };
    }    


    return {depositProcess, setDepositProcess, approveAmountAsync, depositEscrowAsync};
}
