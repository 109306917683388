
type ComponentProps = {
    id: `0x${string}` | undefined,
};
const ShortTxnId: React.FC<ComponentProps> = ({ id }) =>  {
    var display: string = "Please Connect";

    if( (id === undefined) || (id.length === 0) || (id === "0x0") )
        id = "0x000000";

    display = id.slice(0,4) + "..." + id.slice(-6);

    return (
        <span>{display}</span>
    );
};

export default ShortTxnId;