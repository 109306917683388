import  { useState, useEffect } from "react";
import  { 
    Typography, Button, Grid, TextField, Box, FormControlLabel, 
    Checkbox, Tooltip, Dialog, DialogTitle, DialogContent, 
    DialogActions, CircularProgress, IconButton, Snackbar,  
}                           from "@mui/material";
import  ContentCopyIcon     from '@mui/icons-material/ContentCopy';
import  CheckCircleIcon     from '@mui/icons-material/CheckCircle';

import  dayjs               from 'dayjs';
import  utc                 from 'dayjs/plugin/utc';

import  { watchNetwork }    from '@wagmi/core';
import  { watchAccount }    from '@wagmi/core';

import  { IEscrowInfo, newIEscrowInfo } from "../../interfaces";
import  { ITokenInfo }                  from "../../interfaces/ITokenInfo";

import  { 
    IDepositProcess, useDeposit, removeEscrowAsync, 
    refundEscrowAsync, settleEscrowAsync,  
}                               from "./Helpers";
import  * as Theme              from "../EskrowTheme";
import  * as Helpers            from "../../helpers/general/Helpers";
import  { useWeb3ClientLogic }  from "../../hooks";
import  { 
    escrowStatus, fetchEscrowDetailsByIdAsync, getTokenInfo,
    partyRelationship, selfparty, watchEkrowEventAsync, 
}                               from "../../helpers";

import  EscrowLifecycle         from "./EscrowLifecycle";
import  ShortAddress            from "../ShortAddress";
import  DepositModal            from "./DepositModal";

import "../myCss.css";



let lastEscrowLoad = dayjs().add(-10, "second");
type ComponentProps = {
    setPageName?: (newValue: string) => void;
};
const ContentEscrow: React.FC<ComponentProps>     = ({ setPageName }) => {
    dayjs.extend(utc);

    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();

    const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);

    const [isInfoDialogOpen, setIsInfoDialogOpen]       = useState(false);
    const [infoDialogMessage, setInfoDialogMessage]     = useState("");
    const [isInfoDialogShowSpinner, setIsDepositDialogShowSpinner]   = useState(true);
    const handleInfoDialogCloseButton = () => {
        setIsInfoDialogOpen(false);
        setIsDepositDialogShowSpinner(true);
    }

    const [isSnackbarOpen, setIsSnackbarOpen]               = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage]             = useState<string>("empty");
    const [snackbarShowDuration, setSnackbarShowDuration]   = useState(2000);
    const [snackbarFlashColor, setSnackbarFlashColor]       = useState<string>("green");
    const handleCloseSnackbar = () => {
        setIsSnackbarOpen(false);
        setSnackbarMessage("");
        setSnackbarFlashColor("green");
        setSnackbarShowDuration(2000);
    };


    const [urlEscrowId, setUrlEscrowId]     = useState<`0x${string}`>(Helpers.getUrlParameter("escrowId", "0x0"));
    const [urlContractId, setUrlContractId] = useState<number>(Helpers.getUrlParameter("contractId"));
    const [escrow, setEscrow]               = useState<IEscrowInfo | null>(null);
    const isPastSettleFrom                  = dayjs().utc().unix() >= (escrow?.settleFromEpoch ?? 0);
    const isCanSettleNow                    = escrow?.isReadyToSettle && !escrow?.isFullyWithdrawn;
    const {isInFundingPeriod}               = escrowStatus(escrow) || true;



    // Web3 Client related logic
    const {
        supportedChains, connectedChainId, connectedChainName, 
        connectedWallet, urlChainName, urlChainId,
        tokenList, setTokenList
    } = useWeb3ClientLogic("escrow");
    // ---

    const unwatchNetwork = watchNetwork((network) => {loadEscrowDebounced()});
    const unwatchAccount = watchAccount((account) => {loadEscrowDebounced()});

    Helpers.setPageMeta({
        title:          `Eskrow | ${urlChainName} | Escrow Id ${(urlEscrowId ?? "0x0")}`,
        description:    `Eskrow | Details for Escrow Id ${urlEscrowId}`,
    });



    const {
        depositProcess, setDepositProcess, 
        approveAmountAsync, depositEscrowAsync
    } = useDeposit({
            escrowId: urlEscrowId ?? "0x0", 
            contractId: urlContractId,
            chainId: connectedChainId, 
            setIsDepositDialogOpen, 
            setEscrow,
            setIsSnackbarOpen,
            setSnackbarMessage,
    });



    // Onload ...
    // useEffect(() => {
    //     setPageName?.("Escrow Contract");
    //     // loadEscrow();
    // }, []);

    // Reload data if wallet/chain is changed
    useEffect( () => {loadEscrowDebounced()}, [connectedWallet, connectedChainId])


    
    function loadEscrowDebounced() {
        // Block multiple calls, max once every 2 seconds.
        if( dayjs().diff(lastEscrowLoad, "seconds") >= 2)
        {
            lastEscrowLoad = dayjs();
            setDepositProcess(IDepositProcess("", false, false));
            fetchEscrowDetailsByIdAsync({escrowId: urlEscrowId, chainId: connectedChainId, contractId: urlContractId})
                .then((e) => {
                    console.log(`Loaded ${urlEscrowId} ...`);
                    if( e?.escrowId === ("0x" + "0".repeat(64))) {
                        window.location.href ="/missing?escrowId=" + urlEscrowId + "&chainId=" + connectedChainId + "&contractId=" + urlContractId;
                    } 
                    else {setEscrow(e);}
                });
        } else {}
    }



    // 1. Find out missing tokens
    const [unknownTokens, setUnknownTokens] = useState<`0x${string}`[]>([]);
    useEffect(() => {
        if(escrow === null) {return}
    
        let missingTokens: `0x${string}`[] = [];
        if( tokenList.find((t) => Helpers.isSame(t.address, escrow?.token1)) == undefined )
        {missingTokens.push(escrow?.token1 as `0x${string}`)}
        
        if( tokenList.find((t) => Helpers.isSame(t.address, escrow?.token2)) === undefined )
        {missingTokens.push(escrow?.token2 as `0x${string}`)}
        
        const unique    = new Set(missingTokens);
        missingTokens   = Array.from(unique);
        setUnknownTokens(missingTokens);

    }, [escrow]);


    // 2. Download missing tokens details
    useEffect(() => {
        async function fetchMissingTokensAsync(tokens: `0x${string}`[], chainId: number) {
            if (tokens.length > 0) {
                const promises      = tokens.map(addr => Helpers.fetchTokenDetails(addr, chainId));
                const newDetails    = await Promise.all(promises);
                const newList       = [...newDetails, ...tokenList];
            
                const unique        = new Set(newList);
                const uniqueList    = Array.from(unique);
                setTokenList(uniqueList);
            }
        }
        fetchMissingTokensAsync(unknownTokens, connectedChainId);
    }, [unknownTokens]);
    


    // -----
    // ENTRY POINT (when DEPOSIT button is clicked)
    function handleApproveButton(party: `0x${string}`, token: ITokenInfo, amount: number, setIsRequestingAllowance: (value: boolean) => void ) {
        approveAmountAsync(party, token, amount, setIsRequestingAllowance);
    }


    let unwatchDepositError: (() => void) | null;
    let unwatchDepositOk: (() => void) | null;
    function handleDepositButton(party: `0x${string}`, token: ITokenInfo, amount: number) {
        watchEkrowEventAsync(connectedChainId, "EscrowError", depositErrorHandler).then( (uw) => unwatchDepositError = uw);
        watchEkrowEventAsync(connectedChainId, "EscrowFunded", depositSuccessHandler).then( (uw) => unwatchDepositOk = uw);

        depositEscrowAsync(party, token, amount, urlContractId, depositNonEventErrorHandler);
    }

    function handleDepositCancelButton() {
        setDepositProcess(IDepositProcess("", false, false));
        setIsDepositDialogOpen(false);
    }


    function depositSuccessHandler(log: any) {
        if( Helpers.isSame(log[0].args._party1, connectedWallet) || 
            Helpers.isSame(log[0].args._party2, connectedWallet)
        ) {
            unwatchDepositOk?.();
            setSnackbarMessage("Funded");
            setIsSnackbarOpen(true);
        }
    }
    
    // Error on the Wallet level (non-Eskrow Events)
    function depositNonEventErrorHandler(err: Error) {
        ["ERC20: transfer amount exceeds balance"]
        .forEach( (msg) => {
            if(err.message.includes(msg)) {
                setSnackbarMessage("FAILED: " + msg);
                setIsSnackbarOpen(true);
            }
        });
    }

    function depositErrorHandler(log: any) {
        if( Helpers.isSame(
            log[0].args._origin, connectedWallet) || 
            Helpers.isSame(log[0].args._method, "deposit")
        ) {
            unwatchDepositError?.();
            const errorCode = log[0].args._code;
            setInfoDialogMessage(errorCode);
            setIsDepositDialogShowSpinner(false);
            setIsInfoDialogOpen(true);
    }
    }
    // ----- ----- -----



    // -----
    // ENTRY POINT (when REFUND button is clicked)
    async function handleRefundButtonAsync(escrowId: `0x${string}`, contractId: number) {
        setInfoDialogMessage("Please approve REFUND on wallet, and wait for confirmation on chain. ");
        setIsInfoDialogOpen(true);
        await refundEscrowAsync(escrowId, connectedChainId, contractId,
            setIsSnackbarOpen, setSnackbarMessage).then(() => loadEscrowDebounced());
        handleInfoDialogCloseButton();
    }
    // ----- ----- -----



    // -----
    // ENTRY POINT (when SETTLE button is clicked)
    async function handleSettleButtonAsync(escrowId: `0x${string}`) {
        setInfoDialogMessage("Please approve SETTLEMENT on wallet, and wait for confirmation on chain. ");
        setIsInfoDialogOpen(true);
        await settleEscrowAsync(escrowId, connectedChainId, urlContractId,
            setIsSnackbarOpen, setSnackbarMessage).then(() => loadEscrowDebounced());
        setIsInfoDialogOpen(false);
    }
    // ----- ----- -----



    // -----
    // ENTRY POINT (when REMOVE ESCROW button is clicked)
    async function handleRemoveEscrowButtonAsync(escrowId: `0x${string}`, contractId: number) {
        setInfoDialogMessage("Please approve the REMOVAL (Delete) on wallet, and wait for on-chain confirmation. ");
        setIsInfoDialogOpen(true);
        await removeEscrowAsync(escrowId, connectedChainId, contractId, 
            setIsSnackbarOpen, setSnackbarMessage)
            .then(() => window.location.href = Helpers.buildUrl("/app", [["chainId", urlChainId.toString()]]));
        handleInfoDialogCloseButton();
        // If removal is completed properly, this line will not execute
    }
    // ----- ----- -----


    const handleCopyEscrowIdClick = () => {
        navigator.clipboard.writeText(window.location.href as string).then(() => {
            setSnackbarMessage("Copied");
            setIsSnackbarOpen(true);
        });
    };



    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h3 = isLg ? "h3" : (isMd ? "h4" : "h5");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");

    const body1 = isLg ? "body1" : (isSm ? "body2" : "body2");



    const partyTextColor = (party: number) => {
        if( partyRelationship(party, escrow?.party1, escrow?.party2, connectedWallet) === "YOU")
        {
            return "black";
        }
        return "gray";
    }


    return (
        <main>
            <br />
            <Typography variant={h4} color="#1976d2" style={{ display: 'inline' }}>
                {connectedChainName}
            </Typography> {" "}
            <Typography variant={body1} style={{ display: 'inline' }}>
                    | <b>FREE</b> TO USE{" "}
                    <Button variant = "contained"
                            color   = "success"
                            size    = "small"
                    >Pay Only Gas</Button>
            </Typography>
            <br/>

            <EscrowLifecycle escrow={escrow} />

            <Box sx={{ border: '5px solid #1976d2', margin: "10px", padding: "10px" }}>

                {/* Top Section (Escrow Id & Description) */}
                <Grid container spacing={1}>
                    <Grid item md={9} xs={12} >
                        <br />
                        <TextField
                            label       = {`Escrow Id ( ${Helpers.shortenTxnId(escrow?.escrowId)} )`}
                            value       = {escrow?.escrowId as string || "0x0"}
                            variant     = 'outlined'
                            sx          = {{width: isMd ? '70%' : "80%", margin: '10px',}}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { textAlign: 'left', fontSize: "9pt", },
                            }}
                        />
                        <Tooltip title="Click to copy">
                            <IconButton onClick={handleCopyEscrowIdClick}>
                                <ContentCopyIcon sx={{fontSize: isMd ? "22pt" : "14pt"}} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={3} xs={12} alignItems="center">
                        {isMd ? <br /> : null}
                        <Button variant = "contained"
                            color       = "error"
                            size        = {"small"}
                            sx          = {{textTransform: "none"}}
                            onClick     = {() => handleRemoveEscrowButtonAsync(escrow?.escrowId ?? "0x0", urlContractId)}
                            disabled    = {
                                ((escrow?.token1DepositedQty ?? BigInt(0)) + (escrow?.token2DepositedQty ?? BigInt(0))) === BigInt(0)
                                ? false
                                : true
                            }
                        >
                            Remove Escrow
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            label       = {"Description"}
                            value       = {escrow?.description as string || ""}
                            variant     = 'outlined'
                            sx          = {{width: "98%", margin: '10px',}}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { textAlign: 'left', fontSize: "11pt" },
                            }}
                        />
                        <br />
                    </Grid>



                    {/* Dates Section (Earliest Settlement & Expiry) */}
                    <Grid container item md={6} xs={12} marginBottom={"10px"}>
                        <TextField 
                            label       = {"Earliest Settlement (UTC)"}
                            value       = {escrow?.settleFromEpoch.toString() === "0" ? "Immediate" : Helpers.getDateTimeFromGmtEpoch(escrow?.settleFromEpoch).toString() as string || ""}
                            variant     = 'outlined'
                            sx          = {{width: '100%', margin: '10px', marginBottom: '1px', }}
                            error       = {!isPastSettleFrom}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { 
                                    textAlign   : 'left', 
                                    fontSize    : "10pt", 
                                    color       : isPastSettleFrom ? "green" : "red"
                                },
                                
                            }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12} marginBottom={"10px"}>
                        <TextField 
                            label       = {"Expiry (UTC)"}
                            value       = {Helpers.getDateTimeFromGmtEpoch(escrow?.expiryEpoch).toString() as string || ""}
                            variant     = 'outlined'
                            error       = {escrow?.isExpired}
                            sx          = {{width: '98%', margin: '10px', marginBottom: "1px", }}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { 
                                    textAlign   : 'left', 
                                    fontSize    : "10pt", 
                                    color       : escrow?.isExpired ? "red" : "green"
                                },
                            }}
                        />
                    </Grid>



                    {/* PARTIES SECTION (Left / Party 1) */}
                    <Grid container item xs={12} md={6}
                        textAlign   = { isMd ? "right" : "left"}
                        paddingLeft = { isMd ? "20px" : "5px" }
                        paddingRight= { isMd ? "5px" : "20px" }
                        paddingTop  = {"20px"}
                        marginTop   = {"40px"}
                        sx          = {{position: 'relative'}}
                        border      = {partyRelationship(1, escrow?.party1, escrow?.party2, connectedWallet) === "YOU" ? "2px solid #1976d2" : "none"}
                        bgcolor     = {partyRelationship(1, escrow?.party1, escrow?.party2, connectedWallet) === "YOU" ? "#f0f0ff" : ""} 
                    >
                        {
                            partyRelationship(1, escrow?.party1, escrow?.party2, connectedWallet) === "YOU"
                            ? (
                                <Typography
                                    variant         = "subtitle2"
                                    color           = "white"
                                    paddingLeft     = "10px"
                                    paddingRight    = "10px"
                                    sx=         {{ 
                                                    position:       'absolute', 
                                                    top:            '-10pt', 
                                                    backgroundColor: "#1976d2" 
                                                }}
                                >
                                    YOU (Party 1)
                                </Typography>
                            )
                            : null
                        }

                        <TextField 
                            label       = {`Party 1 (${partyRelationship(1, escrow?.party1, escrow?.party2, connectedWallet)}) .  ${Helpers.shortenAddress(escrow?.party1)}` as string || ""}
                            value       = {escrow?.party1 as string || ""}
                            variant     = 'outlined'
                            error       = {escrow?.isExpired}
                            sx          = {{width: '100%', marginTop: "20px"}}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { 
                                    textAlign   : 'left',
                                    fontSize    : "10pt",
                                    color       : partyTextColor(1),
                                },
                            }}
                        /><br />

                        <Grid container>
                            <Grid item xs={8} paddingTop={"20px"}>
                                <TextField 
                                    label   = {Helpers.goet(escrow?.token1DepositedQty, escrow?.token1RequiredQty) ? "FULLY FUNDED" : "Funded Amount"}
                                    value   = {Helpers.quantityToAmount(Helpers.toDecimal(escrow?.token1DepositedQty ?? 0), getTokenInfo(escrow?.token1, tokenList)?.decimals)?.toLocaleString() as string || ""}
                                    variant = "outlined"
                                    sx      = {{ width: '98%', margin: '10px' }}
                                    inputProps  = {{
                                        readOnly: true,
                                        style: { 
                                            textAlign:          'right',
                                            fontSize:           "42pt",
                                            color:              partyTextColor(1),
                                            backgroundColor:    Helpers.goet(escrow?.token1DepositedQty, escrow?.token1RequiredQty) ? "#e0f0e0" : "",
                                        },
                                    }}
                                />

                            </Grid>

                            <Grid item xs={4} paddingTop={"20px"}>
                                <TextField
                                    label       = {"Token"}
                                    value       = {getTokenInfo(escrow?.token1, tokenList)?.symbol as string || ""}
                                    variant     = 'outlined'
                                    sx          = {{width: '90%', margin: '10px',}}
                                    inputProps  = {{
                                        readOnly    : true,
                                        style       : { 
                                            textAlign:      'right', 
                                            fontSize:       "16pt", 
                                            padding:        "2px", 
                                            color:          partyTextColor(1),
                                            paddingRight:   "10px" },
                                    }}
                                />
                                <br />
                                <TextField 
                                    label       = {"Out Of (Agreed)"}
                                    value       = {Helpers.quantityToAmount(Helpers.toDecimal(escrow?.token1RequiredQty ?? 0), getTokenInfo(escrow?.token1, tokenList)?.decimals)?.toLocaleString() as string || ""}
                                    variant     = 'outlined'
                                    sx          = {{width: '90%', margin: '5px', marginBottom: "0px"}}
                                    inputProps  = {{
                                        readOnly    : true,
                                        color       : partyTextColor(1),
                                        style       : { 
                                            textAlign:      'right', 
                                            fontSize:       "16pt", 
                                            padding:        "2px", 
                                            color:          partyTextColor(1),
                                            paddingRight:   "10px", 
                                        },
                                    }}
                                />
                                <br />

                                <Tooltip
                                    title   = {"Assets deposited, must be used for Escrow settlement, or only be returned after Expiry, if not settled."}
                                >
                                    <FormControlLabel
                                        labelPlacement  = "end"
                                        control         = {<Checkbox checked={escrow?.isParty1LockRefund as boolean || false} size="medium" disabled={true} />}
                                        label           = {
                                            <Typography sx = {{ 
                                                fontSize:   '10pt',                                             color:          partyTextColor(1),
                                                padding:    "0px", 
                                                margin:     "0px" 
                                                }} 
                                            >
                                                Is Locked
                                            </Typography>
                                        }
                                    />
                                </Tooltip>

                            </Grid>
                            <Grid item xs={12} paddingBottom={"20px"}>
                                {/* Party 1 DEPOSIT button */}
                                <Button variant     = "contained" 
                                        disabled    = {
                                            !Helpers.isSame(connectedWallet, escrow?.party1) || 
                                            !isInFundingPeriod || 
                                            (escrow?.token1DepositedQty === escrow?.token1RequiredQty) 
                                        }
                                        onClick     = {() => setIsDepositDialogOpen(true)}
                                >
                                    Deposit
                                </Button> {" "}
                                <Button variant     = "contained" 
                                        color       = "warning"
                                        onClick     = {() => handleRefundButtonAsync(escrow?.escrowId ?? "0x0", urlContractId)}
                                        disabled    = { escrow?.isParty1LockRefund || 
                                                        isCanSettleNow || 
                                                        !escrow ||
                                                        escrow?.hasParty1Withdrawn ||
                                                        !Helpers.isSame(connectedWallet,escrow?.party1) ||
                                                        (escrow?.token1DepositedQty === BigInt(0))
                                        }
                                >
                                    Refund
                                </Button> {" "}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSettleButtonAsync(escrow?.escrowId ?? "0x0")}
                                    disabled={
                                        !escrow?.isReadyToSettle || 
                                        !Helpers.isSame(connectedWallet, escrow.party1) ||
                                        escrow.hasParty1Withdrawn
                                    }
                                    style={{ marginRight: '8px' }}
                                >
                                    {escrow?.hasParty1Withdrawn ? (
                                        <>
                                            Already Settled
                                            <CheckCircleIcon style={{ fontSize: "14pt", color: "green", marginLeft: "4px" }} />
                                        </>
                                    )
                                    : ("Settle")
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                    </Grid>



                    {/* PARTIES SECTION (Right / Counterparty) */}
                    <Grid container item xs={12} md={6}
                        textAlign   = { isMd ? "right" : "left"}
                        paddingLeft = { isMd ? "5px" : "20px" }
                        paddingRight= { isMd ? "20px" : "5px" }
                        paddingTop  = {"20px"}
                        marginTop   = {"20px"}
                        sx          = {{position: 'relative'}}
                        border      = {partyRelationship(2, escrow?.party1, escrow?.party2, connectedWallet) === "YOU" ? "2px solid #1976d2" : "none"}
                        bgcolor     = {partyRelationship(2, escrow?.party1, escrow?.party2, connectedWallet) === "YOU" ? "#f0f0ff" : ""} 
                    >
                        {
                            partyRelationship(2, escrow?.party1, escrow?.party2, connectedWallet) === "YOU"
                            ? (
                                <Typography
                                    variant         = "subtitle2"
                                    color           = "white"
                                    paddingLeft     = "10px"
                                    paddingRight    = "10px"
                                    sx=         {{ 
                                                    position:       'absolute', 
                                                    top:            '-10pt', 
                                                    backgroundColor: "#1976d2" 
                                                }}
                                >
                                    YOU (Party 2)
                                </Typography>
                            )
                            : null
                        }

                        <TextField 
                            label       = {`Party 2 (${partyRelationship(2, escrow?.party1, escrow?.party2, connectedWallet)}) .  ${Helpers.shortenAddress(escrow?.party2)}` as string || ""}
                            value       = {escrow?.party2 as string || ""}
                            variant     = 'outlined'
                            error       = {escrow?.isExpired}
                            sx          = {{width: '100%', marginTop: "20px"}}
                            inputProps  = {{
                                readOnly    : true,
                                style       : { 
                                    textAlign   : 'left', 
                                    fontSize    : "10pt", 
                                    color       : partyTextColor(2) 
                                },
                            }}
                        /><br />

                        <Grid container>
                            <Grid item xs={8} paddingTop={"20px"}>
                                <TextField 
                                    label   = {Helpers.goet(escrow?.token1DepositedQty, escrow?.token1RequiredQty) ? "FULLY FUNDED" : "Funded Amount"}
                                    value   = {Helpers.quantityToAmount(Helpers.toDecimal(escrow?.token2DepositedQty ?? 0), getTokenInfo(escrow?.token2, tokenList)?.decimals)?.toLocaleString() as string || ""}
                                    variant = "outlined"
                                    sx      = {{ width: '95%', margin: '10px' }}
                                    inputProps  = {{
                                        readOnly: true,
                                        style: { 
                                            textAlign   : 'right',
                                            fontSize    : "42pt",
                                            color       : partyTextColor(2),
                                            backgroundColor: Helpers.goet(escrow?.token2DepositedQty, escrow?.token2RequiredQty) ? "#e0f0e0" : "",
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} paddingTop={"20px"}>
                                <TextField
                                    label       = {"Token"}
                                    value       = {getTokenInfo(escrow?.token2, tokenList)?.symbol as string || ""}
                                    variant     = 'outlined'
                                    sx          = {{width: '90%', margin: '10px',}}
                                    inputProps  = {{
                                        readOnly    : true,
                                        style       : { 
                                            textAlign       : 'right', 
                                            fontSize        : "16pt", 
                                            padding         : "2px", 
                                            paddingRight    : "10px", 
                                            color           : partyTextColor(2),
                                        },
                                    }}
                                />
                                <br />
                                <TextField 
                                    label       = {"Out Of (Agreed)"}
                                    value       = {Helpers.quantityToAmount(Helpers.toDecimal(escrow?.token2RequiredQty ?? 0), getTokenInfo(escrow?.token2, tokenList)?.decimals)?.toLocaleString() as string || ""}
                                    variant     = 'outlined'
                                    sx          = {{width: '90%', margin: '5px', marginBottom: "0px"}}
                                    inputProps  = {{
                                        readOnly    : true,
                                        style       : { 
                                            textAlign       : 'right', 
                                            fontSize        : "16pt", 
                                            padding         : "2px", 
                                            paddingRight    : "10px", 
                                            color           : partyTextColor(2),
                                        },
                                    }}
                                />
                                <br />

                                <Tooltip
                                    title   = {"Assets deposited, must be used for Escrow settlement, or only be returned after Expiry, if not settled."}
                                >
                                    <FormControlLabel
                                        labelPlacement  = "end"
                                        control         = {<Checkbox checked={escrow?.isParty2LockRefund as boolean || false} size="medium" disabled={true} />}
                                        label           = {
                                            <Typography sx={{ fontSize: '10pt', padding: "0px", margin: "0px" }}>
                                                Is Locked
                                            </Typography>
                                        }
                                    />
                                </Tooltip>

                            </Grid>
                            <Grid item xs={12} paddingBottom={"20px"} textAlign={"left"}>
                                {/* Party 2 DEPOSIT button */}
                                <Button variant     = "contained" 
                                        disabled    = {
                                            !Helpers.isSame(connectedWallet, escrow?.party2) || 
                                            !isInFundingPeriod || 
                                            (escrow?.token2DepositedQty === escrow?.token2RequiredQty) 
                                        }
                                        onClick     = {() => setIsDepositDialogOpen(true)}
                                >
                                    Deposit
                                </Button> {" "}
                                <Button variant     = "contained" 
                                        color       = "warning"
                                        onClick     = {() => handleRefundButtonAsync(escrow?.escrowId ?? "0x0", urlContractId)}
                                        disabled    = { escrow?.isParty2LockRefund || 
                                                        isCanSettleNow || 
                                                        !escrow ||
                                                        escrow?.hasParty2Withdrawn ||
                                                        !Helpers.isSame(connectedWallet,escrow?.party2) ||
                                                        (escrow?.token2DepositedQty === BigInt(0))
                                        }
                                >
                                    Refund
                                </Button> {" "}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSettleButtonAsync(escrow?.escrowId ?? "0x0")}
                                    disabled={
                                        !escrow?.isReadyToSettle || 
                                        !Helpers.isSame(connectedWallet, escrow.party2) ||
                                        escrow.hasParty2Withdrawn
                                    }
                                    style={{ marginRight: '8px' }}
                                >
                                    {escrow?.hasParty2Withdrawn ? (
                                        <>
                                            Already Settled
                                            <CheckCircleIcon style={{ fontSize: "14pt", color: "green", marginLeft: "4px" }} />
                                        </>
                                    )
                                    : ("Settle")
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                    </Grid>


                    <Grid container marginTop={"30px"}>
                        <Grid item xs={12} textAlign={"center"}>
                            <Button variant = "contained" 
                                color   = {"primary"}
                                size    = {"large"}
                                sx      = {{textTransform: "none"}}
                                onClick = {() => {
                                    const url = Helpers.buildUrl("/app", [["chainId", urlChainId.toString()]]);
                                    window.location.href = url.toString();
                                }}
                            >
                                Back To dApp
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>



            </Box>


            {/* DEPOSIT ASSET MODAL POP UP BOX */}
            <DepositModal
                chainId                 = {connectedChainId}   
                chainName               = {connectedChainName}   
                escrow                  = {escrow ?? newIEscrowInfo()}
                party                   = {selfparty(escrow, connectedWallet) ?? (connectedWallet ?? "0x0")}
                isDepositDialogOpen     = {isDepositDialogOpen}
                setIsDepositDialogOpen  = {setIsDepositDialogOpen}
                depositProcess          = {depositProcess}
                tokenList               = {tokenList}
                handleApproveButton     = {handleApproveButton}
                handleDepositButton     = {handleDepositButton}
                handleDepositCancelButton   = {handleDepositCancelButton}
            />


            {/* DIALOG for Wallet Approval Status */}
            <Dialog open={isInfoDialogOpen} onClose={() => handleInfoDialogCloseButton()}>
                <DialogTitle    fontSize={"11pt"} 
                                style={{ backgroundColor: '#1976d2', color: 'white' }}
                >
                    Wallet Approval (<ShortAddress addr={connectedWallet} />) {urlChainName}
                </DialogTitle>
                <DialogContent
                    style   = {{    display:        'flex', 
                                    flexDirection:  'column', 
                                    alignItems:     'center'
                    }}
                >
                    <Typography 
                        variant     = "body2"
                        color       = {"gray"} 
                        fontSize    = {"10pt"} 
                        width       = {"90%"} 
                        textAlign   = {"center"} 
                        padding     = {"10px"} 
                    >
                        {infoDialogMessage} {" "}
                        {
                            isInfoDialogShowSpinner
                            ? <CircularProgress size={16} />
                            : null 
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" 
                            sx={{textTransform: "none"}}
                            onClick = {() => handleInfoDialogCloseButton()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>



            {/* Snackbar Message */}
            <Snackbar
                open                = {isSnackbarOpen}
                autoHideDuration    = {snackbarShowDuration}
                onClose             = {handleCloseSnackbar}
                message             = {snackbarMessage}
                anchorOrigin        = {{horizontal: 'center', vertical: 'bottom'}}
                sx                  = {{backgroundColor: snackbarFlashColor}}
            />
        </main>
    );
};
export default ContentEscrow;