import { 
    mainnet, bsc, polygon, arbitrum, avalanche, optimism, 
    fantom, cronos, klaytn, canto, moonbeam, sepolia,
}                                   from 'wagmi/chains';
import { IExtendedChainInfo }       from '../../interfaces';

// Setup chain list display
export const extendedChainInfos: IExtendedChainInfo[]   = [
    {
        chainId:    bsc.id,
        chain:      bsc,
        chainName:  bsc.name,
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_binance.jpg",
        urlLink:    "bsc",
    },
    {
        chainId:    polygon.id,
        chain:      polygon,
        chainName:  polygon.name,
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_polygon.jpg",
        urlLink:    "polygon",
    },
    {
        chainId:    avalanche.id,
        chain:      avalanche,
        chainName:  avalanche.name,
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_avalanche.jpg",
        urlLink:    "avalanche",
    },
    {
        chainId:    optimism.id,
        chain:      optimism,
        chainName:  "Optimism",
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_optimism.jpg",
        urlLink:    "optimism",
    },
    {
        chainId:    mainnet.id,
        chain:      mainnet,
        chainName:  mainnet.name,
        isTestNet:  false,
        isEnabled:  false,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
        urlLink:    "",
    },
    {
        chainId:    arbitrum.id,
        chain:      arbitrum,
        chainName:  arbitrum.name,
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg",
        urlLink:    "arbitrum",
    },
    {
        chainId:    moonbeam.id,
        chain:      moonbeam,
        chainName:  moonbeam.name,
        isTestNet:  false,
        isEnabled:  true,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_moonbeam.jpg",
        urlLink:    "moonbeam",
    },
    {
        chainId:    fantom.id,
        chain:      fantom,
        chainName:  fantom.name,
        isTestNet:  false,
        isEnabled:  false,
        iconUrl:    "https://icons.llamao.fi/icons/chains/rsz_fantom.jpg",
        urlLink:    "fantom",
    },
    // {
    //     chainId:    goerli.id,
    //     chain:      goerli,
    //     chainName:  goerli.name,
    //     isTestNet:  true,
    //     isEnabled:  true,
    //     iconUrl:    "https://chainlist.org/unknown-logo.png",
    // },
    {
        chainId:    sepolia.id,
        chain:      sepolia,
        chainName:  sepolia.name,
        isTestNet:  true,
        isEnabled:  true,
        iconUrl:    "https://chainlist.org/unknown-logo.png",
        urlLink:    "sepolia",
    },
];