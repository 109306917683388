import { createTheme, ThemeOptions, useMediaQuery, useTheme } from "@mui/material";



export function createEskrowTheme() {
  // Define the breakpoints as a constant
  const breakpoints = {
    xs: 0,
    sm: 600, // Small screens (e.g., mobile)
    md: 960, // Medium screens (e.g., tablets)
    lg: 1024, // Large screens (e.g., desktops)
    xl: 1280, // Extra-large screens (e.g., large desktops)
  };

  // Define a theme options object
  const themeOptions: ThemeOptions = {
    breakpoints: {
      values: breakpoints,
    },
    palette: {
      mode: 'light',
    },
  };

  // Create the theme
  const theme = createTheme(themeOptions);
}

// Export a hook to use the Eskrow theme and selected typography variant
export function useEskrowTheme() {

  const theme = useTheme();
  
  // Media selectors
  const isXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));


  
  return {
    theme, isXs, isSm, isMd, isLg, isXl,
  };
}


// Note: Cannot return as part of useEskrowTheme() as getMediaType() uses useEskrowTheme() (causes recursive calls)
export function getMediaType(): "xs" | "sm" | "md" | "lg" | "xl" {
  const { isXl, isLg, isMd, isSm, isXs } = useEskrowTheme();

  return isXl ? "xl" : (isLg ? "lg" : (isMd ? "md" : (isSm ? "sm" : "xs")));
}