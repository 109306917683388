import  * as React          from 'react';
import  { Box }             from "@mui/material";
import  dayjs, { Dayjs,  }  from 'dayjs';
import  utc                 from 'dayjs/plugin/utc';
import  ShortAddress        from '../ShortAddress';
import  { ITokenInfo }      from '../../interfaces/ITokenInfo';
import  { isAddress }       from '../../helpers';

import '../myCss.css';



type ComponentProps = {
    party1:                     string,
    party2:                     string,
    token1:                     ITokenInfo | null,
    token2:                     ITokenInfo | null,
    token1RequiredQty:          number,
    token2RequiredQty:          number,
    party1LockRefund:           boolean,
    party2LockRefund:           boolean,
    isImmediatelyExecutable:    boolean,
    earliestExecutable:         Dayjs,
    expiry:                     Dayjs,

};
const ContractText: React.FC<ComponentProps> = ({ 
    party1,             token1,         party2,     token2, 
    token1RequiredQty,  token2RequiredQty, 
    party1LockRefund,   party2LockRefund,
    isImmediatelyExecutable, earliestExecutable, expiry
    }) =>  {
    dayjs.extend(utc);

    const ExecutionClause = () => {
        const ImmediateExecution = () => {
            return (
            <span>
                <span className='field-text'>IMMEDIATELY</span> after EFFECTIVE point
            </span>
            )
        };
        const EarliestExecution = () => {
            return (
            <span>
                After the EFFECTIVE point, or <span className='field-text'>Earliest Execution</span> {" "}
                ({earliestExecutable.utc().toString()}) --whichever is later
            </span>
            )
        };

        return (
            <span>
                { isImmediatelyExecutable ? <ImmediateExecution /> : <EarliestExecution /> },
                either Party can {" "}
                <span className='field-text'>EXECUTE</span> {" "}
                the escrow by requesting for a withdrawal.
            </span>
        );
    }



    return (
    <div>
        <Box sx={{ border: '1px solid grey', display: 'flex', height: '100px', fontSize: "10pt" }}>
            <Box sx={{ width: '100px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                A
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    AGREED PARTIES & TOKENS
                </Box>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    Party1 (
                    <span className="field-text">
                        { isAddress(party1) 
                            ? (<ShortAddress addr={party1 as `0x${string}`}/>) 
                            : "..."
                        }
                    </span>), agreed to {" "}
                    <span className="field-text">
                        SEND {" "}
                        {token1RequiredQty + " "}  
                        {(token1 ? token1.symbol : "Token1")}
                    </span>, to Receive {" "}
                    {token2RequiredQty + " "} 
                    {" " + (token2 ? token2.symbol : "Token2") }
                    {" "}
                    from Party2 ({ isAddress(party2)
                                    ? (<ShortAddress addr={party2 as `0x${string}`}/>)
                                    : "..."
                    }).
                    <br/>
                    Party2 (
                    <span className='field-text'> 
                    { isAddress(party2) 
                        ? (<ShortAddress addr={party2 as `0x${string}`}/>) 
                        : "..."
                    }
                    </span>),
                    agreed to {" "}
                    <span className='field-text'>
                        SEND {" "} 
                        {token2RequiredQty + " "}  
                        {(token2 ? token2.symbol : "Token2")}
                    </span>, to Receive {" "}
                    {token1RequiredQty + " "} 
                    {" " + (token1 ? token1.symbol : "Token1") + " "}
                    from Party1 (
                    { isAddress(party1)
                        ? (<ShortAddress addr={party1 as `0x${string}`}/>)
                        : "..."
                    })
                </Box>
            </Box>
        </Box>
        <Box sx={{ border: '1px solid grey', display: 'flex', height: '100%', fontSize: "10pt" }}>
            <Box sx={{ width: '100px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                B
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    DEPOSIT
                </Box>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    1.  With immediate effect, either party can deposit the agreed tokens into this Eskrow smart-contract.
                        Eskrow will safe-keep the deposited tokens, thus eliminating trust issues.<br/>
                    2.  When ALL agreed tokens from both Parties have been deposited, then this escrow shall 
                        become <span className='field-text'>EFFECTIVE</span>.<br/>
                    3.  Once EFFECTIVE, all tokens are Locked, and the escrow can only be Executed.<br/>
                    4.  Before the EFFECTIVE point, Party1 <span className='field-text'>
                        {
                            (party1LockRefund) 
                            ? (" CANNOT") 
                            : (" CAN") 
                        }</span> withdraw (refund) the { token1 ? token1.symbol : "tokens"} {" "} it sent.
                        {   ( (party1LockRefund && !party2LockRefund) )
                                && (party1LockRefund) 
                            ? " eg, Options Seller)"
                            : ""
                        }
                        <br/>
                    5.  Before the EFFECTIVE point, Party2 <span className='field-text'>
                        {
                            (party2LockRefund) 
                            ? (" CANNOT") 
                            : (" CAN") 
                        }</span> withdraw (refund) the {token2 ? token2.symbol : "tokens" } {" "} it sent.
                        {   ( (!party1LockRefund && party2LockRefund) )
                                && (party2LockRefund) 
                            ? " (eg, Options Seller)"
                            : ""
                        }
                </Box>
            </Box>
        </Box>
        <Box sx={{ border: '1px solid grey', display: 'flex', height: '100%', fontSize: "10pt" }}>
            <Box sx={{ width: '100px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                C
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    EXECUTE / DISBURSEMENT
                </Box>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    1.  <ExecutionClause />
                        <br/>
                    2.  Upon which, Eskrow will disburse (transfer) the counterparty-tokens to
                        the requesting party. {" "}
                        --<span className='field-text'>Party1</span> will receive {" "}
                        <span className='field-text'>{token2RequiredQty} {token2 ? token2.symbol : "token2"}</span>
                        ; {" "}
                        <span className='field-text'>Party2</span> will receive {" "}
                        <span className='field-text'>{token1RequiredQty} {token1 ? token1.symbol : "token1"}</span>.<br/>
                    3.  Each party need to request for Disbursement / Withdrawal.<br/>
                    4.  After EFFECTIVE, the escrow will never expire, and either party can withdraw whenever.
                </Box>
            </Box>
        </Box>
        <Box sx={{ border: '1px solid grey', display: 'flex', height: '100%', fontSize: "10pt" }}>
            <Box sx={{ width: '100px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                D
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    EXPIRY / REFUND
                </Box>
                <Box sx={{ textAlign: 'left', p: 1 }}>
                    1.  Should not all agreed tokens are deposited by (<span className='field-text'>Expiry</span>) {expiry.utc().toString()},
                        the escrow becomes EXPIRED.<br/>
                    2.  After EXPIRED, either party can request to REFUND, in which, Eskrow will return the originally Deposited tokens to each party.<br/>
                </Box>
            </Box>
        </Box>
    </div>
    );
};

export default ContractText;