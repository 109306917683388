import  { Typography, Grid, Box, ListItem, Link, }  from "@mui/material";
import  SolidityCodeViewer                         from './SolidityCodeViewer';

import  * as Theme              from "../EskrowTheme";
import  * as Helpers            from "../../helpers/general/Helpers";
import { getEskrowContractLatest, getEskrowContracts } from "../../helpers";
import { IExtendedChainInfo }   from "../../interfaces";
import ShortAddress             from "../ShortAddress";



type ComponentProps = {
    supportedChains: IExtendedChainInfo[],
  };
const ContentSource: React.FC<ComponentProps> = ({supportedChains}) => {
    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();

    Helpers.setPageMeta({
        title:          `Eskrow | Smart Contract Source Code`,
        description:    `Eskrow smart contract source code description`,
        canonical:      location.href,
    });


    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");



return (
    <>
        <br/>
        <Grid container sx={{color: "#606060"}}>
            <Grid item xs={12} md={3} >
                <Box sx={{ border: '5px solid #1976d2', margin: isMd ? "10px" : "1px", padding: isMd ? "10px" : "2px"}}>
                    <Grid container maxWidth={"xl"} >
                        <Typography variant='subtitle2' sx={{margin: '10px'}}>
                            <b>CONTRACTS SOURCE:</b><br/>
                        </Typography>
                        {
                            supportedChains.filter((c) => c.isEnabled)
                            .map((chain, index) => {
                            const contract  = getEskrowContractLatest(getEskrowContracts(chain.chainId).contracts);
                            return (
                                <ListItem key={index} sx={{padding: "0px"}}>
                                <Typography variant='caption' sx={{marginLeft: "10px"}}>
                                    {chain.chainName} ({contract?.version}): {" "}
                                    <Link href={`${chain.chain.blockExplorers?.default.url}/address/${contract?.address}#code`}>
                                        <ShortAddress addr={contract?.address} />
                                    </Link>
                                </Typography>
                                </ListItem>
                            )})
                        }
                    </Grid>
                </Box>
            </Grid>


            <Grid item xs={12} md={8} >
                <br/>
                <Typography variant={h2} >
                    <b>Smart Contract | Eskrow</b>
                </Typography>
                <Typography variant='body1' sx={{padding: '10px'}}>
                    Eskrow is a web3 escrow dApp for cryptocurrencies. It is a smart-contract that 
                    facilitate secure transfers of ERC-20 tokens on any EVM-compatible blockchains. 
                    Developed using Solidity language, Eskrow adheres to industry standards, and best
                    practices by utilizing the <Link href="https://trufflesuite.com/">Truffle Suite</Link>
                    {" "} and <Link href="https://www.openzeppelin.com/">OpenZeppelin</Link> library, 
                    thereby ensuring a secure, audited, reusable and best-practice implementation.
                    <br/><br/>
                    As an <b>open-source</b> escrow initiative, Eskrow emphasizes transparency and user
                    trust. The  source code for the Eskrow smart contract is made publicly accessible on 
                    Etherscan on all the deployed blockchain (links on the left), accompanied by detailed 
                    explanations provided here.
                    <br/><br/>
                    Links to the actual deployed smart-contract (for each chain) can be found on the left.
                    <br/><br/>
                    Eskrow has 2 smart contracts, the <b>EskrowMain</b> and <b>EskrowSub</b>, which is
                    accessed via address whitelisting.
                </Typography>
                <br/>
                <Typography variant={h5}>
                    <b>EskrowMain</b>
                </Typography>
                <SolidityCodeViewer code={codeBlock1} />
                <Typography variant={h5}>
                    <b>Initial Code Block</b>
                </Typography>
                <br />
                <Typography variant="body1">
                    <b>treasuryByToken</b><br />
                    Variable to track the quantity of each type of token, held by the smart contract.
                    <br/><br />
                    <b>escrowIds</b><br />
                    Variable to keep a list of all the escrowIds.
                    <br/><br />
                    <b>escrows</b><br />
                    Variable to keep a list of escrow details, by escrow id.
                    <br/><br />
                    <b>escrowIdsByParty</b><br />
                    Variable to keep a list of escrowIds by wallet address.
                    <br/><br />
                </Typography>
                <br />
                <hr />
                <SolidityCodeViewer code={codeAdminBlock} />
                <Typography variant={h5}>
                    <b>Admin Function Block</b>
                </Typography>
                <br />
                <Typography variant="body1">
                    <b>setConfigIsPaused()</b><br />
                    Call to Pause/Unpause the smart contract (only used for emergency).
                    <br/><br />
                    <b>setConfigFeeAddress()</b><br />
                    Sets the wallet address where fees (if any) will be sent.
                    <br/><br />
                    <b>setConfigFeeInPptPct()</b><br />
                    Sets the fee of the contract.  1 == (1/1000 of 1%). 10 == 0.001%.
                    <br />
                    If fee is set (<b>zero fee</b> now), it will be withheld and retained in the
                    contract during the withdrawal process. It will be sent to the <i>configFeeAddress</i>
                    when the <i>adminWithdrawAccumulatedFees()</i> (OwnerOnly) is called. 
                    <br/><br />
                    <b>setEskrowSub()</b><br />
                    Sets EskrowSub smart contract address. EskrowSub is used for data validation.
                    <br/><br />
                </Typography>
                <hr />
                <SolidityCodeViewer code={codeCreate} />
                <Typography variant="body1">
                    <b>create()</b><br />
                    Function to create/add a new escrow into storage variables.
                    <br/><br />
                </Typography>
                <hr />
                <SolidityCodeViewer code={codeDepositErc20} />
                <Typography variant="body1">
                    <b>depositErc20(bytes32 _escrowId, address _token, uint256 _quantity)</b><br />
                    Function to fund an active escrow. This function will initiate a transfer of 
                    the _token to Eskrow main contract. Therefore, caller need to approve the 
                    transfer/spend in advance, otherwise, the transfer will fail.
                </Typography>
                <hr />
                <SolidityCodeViewer code={codeBlockX} />
                <Typography variant="body1">
                    <b>In Progress ...</b><br />
                    ... documentation comming soon.
                </Typography>
                <hr />                
            </Grid>
        </Grid>
    </>
)
}
export default ContentSource;


const codeBlock1 =
`// SPDX-License-Identifier: GD
pragma solidity ^0.8.19;

import "@openzeppelin/contracts/utils/structs/EnumerableSet.sol";
import "@openzeppelin/contracts/token/ERC20/IERC20.sol";
import "@openzeppelin/contracts/access/Ownable.sol";

import "./EskrowHelpers/EskrowStructs.sol";
import "./EskrowHelpers/EskrowEvents.sol";
import "./EskrowHelpers/IEskrowSub.sol";

contract Eskrow is EskrowEvents, Ownable {
    using EnumerableSet for EnumerableSet.Bytes32Set;

    // ==============================
    // STATE VARIABLES
    //
    string contractName;
    mapping(address => uint256) internal treasuryByToken;
    EnumerableSet.Bytes32Set internal escrowIds;
    mapping(bytes32 => EskrowStructs.Escrow) internal escrows;
    mapping(address => EnumerableSet.Bytes32Set) internal escrowIdsByParty;
    IEskrowSub internal eskrowSub;
    bool internal configIsPaused;
    address public configFeeAddress;
    uint16 public configFeeInPptPct;

    // ==============================
    // FUNCTIONS

    // Initializations
    constructor() {
        contractName = "Eskrow v1.5.5";

        configIsPaused = false;
        configFeeAddress = address(0x0);
        configFeeInPptPct = 0; // 1 ppt == (1/1000 of 1%)
        eskrowSub = IEskrowSub(payable(address(0x0)));
    }

    // Note: Users should NEVER send ETHER (or ANY token) to contract.
    receive() external payable virtual {}

    //----------
    function _breakOnSystemPause() internal view {
        require(
            !configIsPaused,
            "===> HARD EXIT. Contract paused. Please refer to community."
        );

        require(
            address(eskrowSub) != address(0x0),
            "===> Please set EskrowSub adress."
        );
    }

    function _breakOnInvalidEscrowId(bytes32 _escrowId) internal view {
        require(escrowIds.contains(_escrowId), "==> Invalid EscrowId.");
    }

`;


const codeAdminBlock =
`// ===================
// ADMIN CONFIG SETTINGS !!! OWNER ONLY
function setConfigIsPaused(bool _isPause) external onlyOwner {
    configIsPaused = _isPause;
}

function setConfigFeeAddress(address _wallet) external onlyOwner {
    configFeeAddress = _wallet;
}

function setConfigFeeInPptPct(uint16 _feeInPptPct) external onlyOwner {
    configFeeInPptPct = _feeInPptPct;
}

function setEskrowSub(address payable _implementation) external onlyOwner {
    if (_implementation != address(0)) {
        eskrowSub = IEskrowSub(_implementation);
    }
}`;


const codeCreate =
`// ==============================
// ==============================
// Function Group: Create Escrow
function create(
    address payable _party1,
    address payable _party2,
    address _token1,
    uint256 _token1RequiredQty,
    address _token2,
    uint256 _token2RequiredQty,
    bool _isParty1LockRefund,
    bool _isParty2LockRefund,
    uint _expiryEpoch,
    uint _settleFromEpoch,
    string memory _description
) external virtual returns (EskrowStructs.Escrow memory escrow) {
    _breakOnSystemPause();

    escrow = EskrowStructs.Escrow({
        escrowId: 0, // Set by eskrowSub.create()
        party1: _party1,
        party2: _party2,
        token1: _token1,
        token2: _token2,
        token1RequiredQty: _token1RequiredQty,
        token1DepositedQty: 0,
        token2RequiredQty: _token2RequiredQty,
        token2DepositedQty: 0,
        feeInPpt: configFeeInPptPct,
        expiryEpoch: _expiryEpoch,
        settleFromEpoch: _settleFromEpoch,
        isParty1LockRefund: _isParty1LockRefund,
        isParty2LockRefund: _isParty2LockRefund,
        hasParty1Withdrawn: false,
        hasParty2Withdrawn: false,
        description: _description
    });

    escrow = eskrowSub.create(escrow, _msgSender());
    if ((escrow.escrowId != 0) && !escrowIds.contains(escrow.escrowId)) {
        // Add the new escrow into storage
        escrows[escrow.escrowId] = escrow;
        escrowIds.add(escrow.escrowId);
        escrowIdsByParty[_party1].add(escrow.escrowId);
        escrowIdsByParty[_party2].add(escrow.escrowId);

        emit EscrowCreated(escrow.escrowId, _party1, _party2);
    }
    return escrow;
}

`;

const codeDepositErc20 =
`// ==============================
// Function Group: Deposit ERC20 tokens
function depositErc20(
    bytes32 _escrowId,
    address _token,
    uint256 _quantity
) external virtual returns (uint32 _code) {
    _breakOnSystemPause();
    _breakOnInvalidEscrowId(_escrowId);

    (
        uint32 code,
        uint256 token1Qty,
        uint256 token2Qty,
        bool isFullyDeposited
    ) = eskrowSub.validateDeposit(
            _msgSender(),
            escrows[_escrowId],
            _token,
            _quantity
        );

    if (code == 0) {
        if (
            IERC20(_token).transferFrom(
                _msgSender(),
                address(this),
                _quantity
            )
        ) {
            escrows[_escrowId].token1DepositedQty = token1Qty;
            escrows[_escrowId].token2DepositedQty = token2Qty;
            treasuryByToken[_token] += _quantity;

            emit EscrowFunded(_escrowId, _msgSender(), _token, _quantity);

            // Emit event if isFullyDeposited (is not the same as isReadyForSettle)
            if (isFullyDeposited) {
                emit EscrowIsFullyFunded(
                    _escrowId,
                    escrows[_escrowId].party1,
                    escrows[_escrowId].party2
                );
            }

            return 0;
        }
        return 1;
    }
    return code;
}
`;


const codeBlockX = 
`    // ==============================
    // Function Group: Refund escrow
    function refund(bytes32 _escrowId) external virtual returns (uint32 _code) {
        _breakOnSystemPause();
        _breakOnInvalidEscrowId(_escrowId);

        (uint32 code, address token, address bene, uint256 qty) = eskrowSub
            .validateRefund(_msgSender(), escrows[_escrowId], false);

        if (code == 0) {
            if (IERC20(token).transfer(bene, qty)) {
                if (bene == escrows[_escrowId].party1) {
                    treasuryByToken[token] -= escrows[_escrowId]
                        .token1DepositedQty;
                    escrows[_escrowId].token1DepositedQty = 0;
                }

                if (bene == escrows[_escrowId].party2) {
                    treasuryByToken[token] -= escrows[_escrowId]
                        .token2DepositedQty;
                    escrows[_escrowId].token2DepositedQty = 0;
                }

                emit EscrowRefunded(_escrowId, bene, token, qty);
                return 0;
            } else {
                return 1;
            }
        }
        return code;
    }

    // ==============================
    // Function Group: Settle
    function settle(bytes32 _escrowId) external virtual returns (uint32 _code) {
        _breakOnSystemPause();
        _breakOnInvalidEscrowId(_escrowId);

        (uint32 code, address token, address bene, uint256 qty) = eskrowSub
            .validateSettle(_msgSender(), escrows[_escrowId]);

        if (code == 0) {
            if (IERC20(token).transfer(bene, qty)) {
                if (bene == escrows[_escrowId].party1) {
                    escrows[_escrowId].hasParty1Withdrawn = true;
                    treasuryByToken[token] -= escrows[_escrowId]
                        .token2DepositedQty;
                }
                if (bene == escrows[_escrowId].party2) {
                    escrows[_escrowId].hasParty2Withdrawn = true;
                    treasuryByToken[token] -= escrows[_escrowId]
                        .token1DepositedQty;
                }

                emit EscrowSettled(_escrowId, bene, token, qty);

                return 0;
            } else {
                return 1;
            }
        }
        return code;
    }

    // ==============================
    // Function Group: Remove Escrow
    function remove(bytes32 _escrowId) external virtual returns (bool success) {
        _breakOnSystemPause();
        _breakOnInvalidEscrowId(_escrowId);

        uint32 code = eskrowSub.validateRemove(_msgSender(), escrows[_escrowId]); 
        if (code == 0) {
            address party1 = escrows[_escrowId].party1;
            address party2 = escrows[_escrowId].party2;

            escrowIds.remove(_escrowId);
            delete escrows[_escrowId];

            _removeBaseEscrowIdsByParty(_escrowId, party1);
            _removeBaseEscrowIdsByParty(_escrowId, party2);

            emit EscrowRemoved(_escrowId, party1);
            emit EscrowRemoved(_escrowId, party2);
            return true;
        }
        return false;
    }

    function _removeBaseEscrowIdsByParty(
        bytes32 _escrowId,
        address _party
    ) private {
        escrowIdsByParty[_party].remove(_escrowId);
        if (escrowIdsByParty[_party].length() == 0) {
            delete escrowIdsByParty[_party];
        }
    }

    // ==============================
    // ADMIN FUNCTION: Withdraw earned fees (and expired escrows)
    function adminWithdrawAccumulatedFees(
        address _token
    ) external virtual onlyOwner returns (uint256 fees) {
        require(configFeeAddress != address(0), "===> Invalid Fee Address");

        fees =
            IERC20(_token).balanceOf(address(this)) -
            treasuryByToken[_token];
        if (IERC20(_token).transfer(configFeeAddress, fees)) {
            return fees;
        }
        return 0;
    }

    // Do NOT send Ether to contract. Escrow Funding is via the DepositErc20().
    // But, just in case, this allow admin to withdraw any ETH accidentally sent here.
    function adminWithdrawEther(uint256 _gwei) external virtual onlyOwner {
        require(
            configFeeAddress != address(0),
            "===> Invalid Withdrawal Address"
        );
        payable(configFeeAddress).transfer(_gwei);
    }

    // ==============================
    // Function Group: Getters
    //
    //
    function getVersion()
        external
        view
        virtual
        returns (string memory version)
    {
        return contractName;
    }

    //
    function getEscrowExt(
        bytes32 _escrowId
    ) external view virtual returns (EskrowStructs.EscrowExt memory) {
        return eskrowSub.toEscrowExt(escrows[_escrowId], block.timestamp);
    }

    //
    function getEscrowIdsByParty(
        address _party
    ) external view virtual returns (bytes32[] memory) {
        return escrowIdsByParty[_party].values();
    }

    //
    function getAccumulatedFees(
        address _token
    ) external virtual returns (uint256 fees) {
        fees =
            IERC20(_token).balanceOf(address(this)) -
            treasuryByToken[_token];
        return fees;
    }
}
`;