import  { useState, useEffect, Fragment }   from 'react';
import  dayjs, { Dayjs,  }                  from 'dayjs';
import  utc                                 from 'dayjs/plugin/utc';
import  {   TextField, Grid,
            FormControl, Typography,
            Checkbox, FormControlLabel,
            Tooltip, Box, Button, Accordion,
            AccordionSummary, AccordionDetails,
}                                           from "@mui/material";
import  ExpandMoreIcon                      from '@mui/icons-material/ExpandMore';
import  ReportIcon                          from '@mui/icons-material/Report';

import  {   handleChangeOnTokenRequiredQty,
            isAllInputValid,
            FormContractProps,
}                                           from "./NewEscrowFormHelpers";
import  { 
    buildUrl,
    getEskrowContractLatest, 
    getEskrowContracts, isAddress, isSame, shortenAddress, 
}                                           from "../../helpers";
import  * as Theme                          from "../EskrowTheme";
import  TokenSelector                       from "./TokenSelector";
import  ShortAddress                        from '../ShortAddress';
import  MUIDateTimePicker                   from "./MUIDateTimePicker";
import  ContractText                        from "./ContractText";



const FormContract: React.FC<FormContractProps> = ({ 
    tokenList,
    connectedChainId,
    connectedWallet,
    isCreatingNewEscrowFlag,
    createButtonOnClickHandler,
    createdEscrowId,

    party1,                 setParty1,
    party2,                 setParty2,
    token1,                 setToken1,
    token2,                 setToken2,
    token1RequiredQty,      setToken1RequiredQty,
    token2RequiredQty,      setToken2RequiredQty,
    party1LockRefund,       setParty1LockRefund,
    party2LockRefund,       setParty2LockRefund,
    isImmediatelyExec,      setIsImmediatelyExec,
    settleFrom,             setSettleFrom,
    expiry,                 setExpiry,
    description,            setDescription,
}) =>  {
    dayjs.extend(utc);

    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();

    const [now, setNow] = useState<Dayjs>(dayjs());
    const contract      = getEskrowContractLatest(getEskrowContracts(connectedChainId).contracts);

    
    // Validate Inputs
    const isAllInputValidWrapper = () => {
        return isAllInputValid(
            party1, party2, 
            token1, token2, 
            token1RequiredQty, token2RequiredQty, 
            settleFrom, expiry);
    }



    // Onload ...
    useEffect(() => {
        setParty1(connectedWallet);
        const updateTime = setInterval( () => { 
            setNow( dayjs() );
        }, 1000 );
        return () => {clearInterval(updateTime)};
    }, []);

    useEffect(() => {
        setParty1(connectedWallet);
    }, [connectedWallet, setParty1]);



    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h3 = isLg ? "h3" : (isMd ? "h4" : "h5");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");



  return (
    <div>
    <FormControl>
        {
        // ----- Top Section Box
        }
        <Box component="span" sx={{ p: 2, border: '1px solid grey' }}>
            <Grid container spacing={1}>

                {/* --- Description */}
                <Grid item xs={12}>
                    <TextField 
                        label       = {"Description of this escrow"}
                        value       = {description}
                        variant     = 'outlined'
                        sx          = {{width: isLg ? "50%" : "100%"}}
                        inputProps  = {{
                            style       : { textAlign: 'center', fontSize: "10pt" },
                            maxLength   : 64,
                        }}
                        onChange    = {(event: any) => setDescription(event.target.value.trimStart())}
                        placeholder = "Enter a description for this escrow ..."
                    />
                    <br/><br/>
                </Grid>


                {/* ----- Dates Section: Earliest */}
                <Grid item md={6} xs={12} textAlign={"left"} alignItems={"center"}>
                    <MUIDateTimePicker 
                        label           = {"Earliest Settlement @ Local Time"}
                        defaultValue    = {settleFrom}
                        disabled        = {isImmediatelyExec}
                        onChange        = {(value) => { setSettleFrom(value ? value : dayjs()) }}
                    />
                    <Grid container>
                        <Grid item xs={8}>
                            {(() => {
                                if (!settleFrom.isValid()) {
                                    return <Typography variant='caption' color="error">* Invalid date.</Typography>;
                                }
                                if( expiry < settleFrom ) {
                                    return <Typography variant='caption' color="error">* Earliest Execution CANNOT be after Expiry.</Typography>;
                                }
                                return <Typography variant='caption'>
                                    {settleFrom?.utc().format("DD MMM, hh:mm A [@ GMT]")}
                                </Typography>;
                                })()}
                        </Grid>
                        <Grid item xs={4} textAlign={"right"} alignItems={"top"}>
                            <Tooltip
                                arrow
                                placement   = "right"
                                title       = {
                                <>
                                    <Typography variant="body1">Can be Executed Immediately</Typography>
                                    <Typography variant="caption">
                                        If checked (Immediate), then parties can
                                        immediately execute the escrow after funding.
                                        <br/><br/> 
                                        If a date/time is provided, then parties can 
                                        only swap/execute the escrow after that 
                                        date, even if funds has been fully deposited.
                                    </Typography>
                                </>
                            }
                        >
                            <FormControlLabel
                                labelPlacement  = "end"
                                control         = {<Checkbox checked={isImmediatelyExec} size="small" />}
                                onChange        = {(event, checked) => setIsImmediatelyExec(checked)}
                                label           = {
                                    <Typography sx={{ fontSize: '10pt' }}>
                                        Immediate
                                    </Typography>
                                }
                            />
                        </Tooltip>
                        </Grid>
                    </Grid>
                    {isMd ? null : <><br /></>}
                </Grid>



                {/* ----- Dates Section: Expiry */}
                <Grid item md={6} xs={12} textAlign={"left"} alignItems={"center"}>
                    <MUIDateTimePicker 
                        label           = {"Expiry @ Local Time"}
                        defaultValue    = {expiry}
                        onChange        = {(value) => { setExpiry(value ? value : dayjs()) }}
                    />
                    <Tooltip
                        arrow
                        placement   = "top"
                        title       = {
                            <>
                                <Typography variant="body1">Expiry Date/Time</Typography>
                                <Typography variant="caption">
                                    Both parties can withdraw their original tokens 
                                    if escrow has not been fully funded by this date/time.
                                    <br/><br/>
                                    This is true even if the <b>Disallow Party Refund</b> flag has 
                                    been set. Eskrow will periodically clear/remove unfunded-expired 
                                    contracts.
                                </Typography>
                            </>
                        }
                    >
                        <div>
                            {(() => {
                                if (!expiry.isValid()) {
                                    return <Typography variant='caption' color="error">* Invalid date.</Typography>;
                                }
                                if( expiry < settleFrom ) {
                                    return <Typography variant='caption' color="error">* Expiry CANNOT be before Earliest Execution.</Typography>;
                                }
                                return <Typography variant='caption'>
                                    {expiry?.utc().format("DD MMM, hh:mm A [@ GMT]")}
                                </Typography>;
                            })()}
                        </div>
                    </Tooltip>
                </Grid>



                <Grid item xs={12} textAlign={"center"} padding={"0px"} margin={"0px"}>
                    <Typography variant="caption" padding={"0px"} margin={"0px"}>
                        * Time is accurate to the nearest blocktime of its chain *<br/>
                        Ethereum ~12s, 
                        BSC: ~3s, 
                        Polygon/Optimism: ~2s, 
                        Fantom: ~0.7s,
                        Arbitrum: ~0.25s
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        <br /><br />



        {/* ----- Middle Section Box: Parties & Tokens */}
        <Grid container spacing={2}>

            {/* ----- LEFT: Party 1 */}
            <Grid item md={6} xs={12}>
                <Box sx = {{ p: 2, border: '1px solid grey', position: 'relative' }}>
                    <Typography
                        variant         = "subtitle2"
                        color           = "white"
                        paddingLeft     = "10px"
                        paddingRight    = "10px"
                        sx=         {{ 
                                        position:       'absolute', 
                                        top:            '-10pt', 
                                        backgroundColor: "#1976d2" 
                                    }}
                    >
                        Party 1 (YOU)
                    </Typography>
                    <Grid container spacing={0}>

                        {/* ----- Party1 Wallet Address */}
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                value       = {party1}
                                label       = {"Wallet" + (isAddress(party1) ? (".".repeat(10) + shortenAddress(party1) + ")") : "")}
                                variant     = 'filled'
                                inputProps  = {{ style : { fontSize: "10pt" } }}
                                onChange    = {(event: any) => setParty1(event.target.value)}
                                placeholder = "0x0"
                                error       = { !isAddress(party1) || isSame(party1, party2)}
                                helperText  = { (!isAddress(party1) || isSame(party1, party2)) ? "Must be a valid wallet address (0x...), and cannot be same as Party2." : "" }
                            />
                            <br/>
                            <Typography variant="caption">Connected: </Typography>
                            <Typography variant="caption">
                                <span   style = {{  cursor          : 'pointer', 
                                                    textDecoration  : 'underline',
                                                }}
                                        onClick = {() => setParty1(connectedWallet) } >
                                    <ShortAddress addr={connectedWallet} />
                                </span>
                            </Typography>
                            <br/><br />
                        </Grid>


                        {/* ----- Party1 Token */}
                        <Grid item xs={12}>
                            <TokenSelector
                                label       = {"Search token, or paste address ..."}
                                tokenList   = {tokenList} 
                                chainId     = {connectedChainId}
                                token       = {token1}
                                setToken    = {setToken1}
                                onChange    = {(value) => setToken1RequiredQty(0.0)}
                            />
                            <br />
                        </Grid>


                        {/* ----- Party1 Token Quantity */}
                        <Grid item xs={12}>
                            <TextField
                                label       = "Quantity"
                                value       = {token1RequiredQty}
                                onChange    = {(event: any) => handleChangeOnTokenRequiredQty(event.target.value, setToken1RequiredQty)}
                                type        = "number"
                                placeholder = "0.00"
                                inputProps  = {{
                                    sx: {
                                        fontSize    : "36pt",
                                        textAlign   : "right",
                                        "&::placeholder": {textAlign: "right",},
                                    },
                                }}
                                error       = {(token1RequiredQty+token2RequiredQty) === 0}
                                helperText  = {((token1RequiredQty+token2RequiredQty) === 0) ? "* Agreed qty for both, Cannot be Zero." : "" }
                            />
                        </Grid>
                        <br/>


                        {/* ----- Party1 IsLockRefund */}
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <Tooltip title      = {
                                <Fragment>
                                    <Typography variant="body1">Lock Funded Asset</Typography>
                                    <Typography variant="caption">
                                        If checked, then assets in partially-funded escrows are 
                                        prevented from refund until expiry.<br/>
                                        Eg; the "Refund" button will be disabled.<br/><br/> 
                                        Note:<br/>
                                        This is useful for <b>OPTIONS</b> (Sellers are {" "}
                                        <b>obligated</b> to Buy/Sell).
                                    </Typography>
                                </Fragment>
                            }
                            arrow
                            placement       = "right"
                            >
                                <FormControlLabel 
                                    control     = {<Checkbox checked={party1LockRefund} />} 
                                    onChange    = {(event, checked) => setParty1LockRefund(checked)}
                                    label       = {
                                        <Typography sx={{ fontSize: '10pt' }}>
                                            Lock Funded Assets
                                        </Typography>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <br /><br />
            </Grid>



            {/* ----- RIGHT: Party 2 */}
            <Grid item md={6} xs={12}>
                <Box sx = {{ p: 2, border: '1px solid grey', position: 'relative' }}>
                    <Typography
                        variant         = "subtitle2"
                        color           = "white"
                        paddingLeft     = "10px"
                        paddingRight    = "10px"
                        sx=         {{ 
                                        position:       'absolute', 
                                        top:            '-10pt', 
                                        backgroundColor: "#1976d2" 
                                    }}
                    >
                        Party 2 (COUNTERPARTY)
                    </Typography>



                    <Grid container spacing={0}>

                        {/* ----- Party2 Wallet */}
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                value       = {party2}
                                label       = {"Wallet" + (isAddress(party2) ? (".".repeat(10) + shortenAddress(party2) + ")") : "")}
                                variant     = 'filled'
                                inputProps  = {{ style : { fontSize: "10pt" } }}
                                onChange    = {(event: any) => setParty2(event.target.value)}
                                placeholder = "0x0"
                                error       = { !isAddress(party2) || isSame(party1, party2)}
                                helperText  = { (!isAddress(party2) || isSame(party1, party2)) ? "Must be a valid wallet address (0x... ), and cannot be same as Party 1." : "" }
                            />
                            <br/><br />.
                        </Grid>


                        {/* ----- Party2 Token */}
                        <Grid item xs={12}  sx={{ textAlign: 'right' }}>
                            <TokenSelector
                                label       = {"Search token, or paste address ..."}
                                tokenList   = {tokenList} 
                                chainId     = {connectedChainId}
                                token       = {token2}
                                setToken    = {setToken2}
                                onChange    = {(value) => setToken2RequiredQty(0.0)}
                            />
                            <br />
                        </Grid>


                        {/* ----- Party2 Token Quantity */}
                        <Grid item xs={12}  sx={{ textAlign: isMd ? "right" : "left" }}>
                            <TextField
                                label       = "Quantity"
                                value       = {token2RequiredQty}
                                onChange    = {(event: any) => handleChangeOnTokenRequiredQty(event.target.value, setToken2RequiredQty)}
                                type        = "number"
                                placeholder = "0.00"
                                inputProps  = {{
                                    sx: {
                                        fontSize    : "36pt",
                                        textAlign   : "right",
                                        "&::placeholder": {textAlign: "right",},
                                    },
                                }}
                                error       = {(token1RequiredQty+token2RequiredQty) === 0}
                                helperText  = {((token1RequiredQty+token2RequiredQty) === 0) ? "* Agreed qty for both, Cannot be Zero." : "" }
                            />
                        </Grid>
                        <br/>


                        {/* ----- Party2 IsLockRefund */}
                        <Grid item xs={12} sx={{ textAlign: isMd ? "right" : "left" }}>
                            <Tooltip title      = {
                                <Fragment>
                                    <Typography variant="body1">Lock Funded Asset</Typography>
                                    <Typography variant="caption">
                                        If checked, then assets in partially-funded escrows are 
                                        prevented from refund until expiry.<br/>
                                        Eg; the "Refund" button will be disabled.<br/><br/> 
                                        Note:<br/>
                                        This is useful for <b>OPTIONS</b> (Sellers are {" "}
                                        <b>obligated</b> to Buy/Sell).
                                    </Typography>
                                </Fragment>
                                }
                                arrow
                                placement       = "right"
                            >
                                <FormControlLabel 
                                    control     = {<Checkbox checked={party2LockRefund} />} 
                                    onChange    = {(event, checked) => setParty2LockRefund(checked)}
                                    label       = {
                                        <Typography sx={{ fontSize: '10pt' }}>
                                            Lock Funded Assets
                                        </Typography>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <br/>



        {/* ----- Bottom Box Section: Escrow TEXT */}
        <Accordion>
            <AccordionSummary
                expandIcon      = {<ExpandMoreIcon />}
                sx              =   {{  backgroundColor:    "#1976d2", 
                                        color:              "white", 
                                        display:            "flex", 
                                    }}
            >
                <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h6" sx={{ fontSize: "11pt", marginRight: "12px", }}>
                        Escrow Details / Language
                    </Typography>
                    <ReportIcon />
                    <Typography variant="caption" sx={{ marginLeft: "4px", }} color="yellow">
                        (Please Read)
                    </Typography>
                </div>
            <br/>

            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#fafaff"}}>
                <ContractText
                    party1                  = {party1}
                    token1                  = {token1}
                    party2                  = {party2}
                    token2                  = {token2}
                    token1RequiredQty       = {token1RequiredQty}
                    token2RequiredQty       = {token2RequiredQty}
                    party1LockRefund        = {party1LockRefund}
                    party2LockRefund        = {party2LockRefund}
                    isImmediatelyExecutable = {isImmediatelyExec}
                    earliestExecutable      = {settleFrom}
                    expiry                  = {expiry}
                />
            </AccordionDetails>
        </Accordion><br/>



        {
            !createButtonOnClickHandler
            ? null
            : (
                <Grid container item spacing={1} xs={12} textAlign={"center"} justifyContent="center">
                    <Button 
                        variant = "contained"
                        size    = "large"
                        color   = "success"
                        disabled= {!isAllInputValidWrapper() || isCreatingNewEscrowFlag}
                        onClick = {createButtonOnClickHandler}
                    >
                        Create Escrow Contract
                    </Button>

                    {createdEscrowId === null ? null : (
                        <Button
                            variant = "contained" 
                            color   = {"success"}
                            size    = {"large"}
                            sx      = {{ textTransform: "none", marginLeft: "18px" }}
                            onClick = {() => {
                                const url = buildUrl("/escrow", [
                                    [ "chainId", connectedChainId.toString() ], 
                                    [ "escrowId", (createdEscrowId ?? "0x0").toString() ], 
                                    [ "contractId", contract?.id.toString() ?? "0" ]
                                    ]
                                );
                                window.location.href = url.toString();
                            }}
                        >
                            View the Escrow
                        </Button>
                    )}

                    <Button
                        variant = "contained" 
                        color   = {"primary"}
                        size    = {"large"}
                        sx      = {{ textTransform: "none", marginLeft: "18px" }}
                        onClick = {() => {
                            const url = buildUrl("/app", [["chainId", connectedChainId.toString()]]);
                            window.location.href = url.toString();
                        }}
                    >
                        Back To dApp
                    </Button>
                </Grid>
            )
        }
        <br/>

    </FormControl>
    </div>
  );
};

export default FormContract;