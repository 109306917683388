import  { Fragment }                from "react";
import  { Typography, Grid, Box, }  from "@mui/material";
import  CheckCircleIcon             from '@mui/icons-material/CheckCircle';

import  dayjs               from 'dayjs';
import  utc                 from 'dayjs/plugin/utc';

import  * as Theme          from "../EskrowTheme";
import  { escrowStatus, }   from "../../helpers";
import  { IEscrowInfo }     from "../../interfaces";
import  ShortTxnId          from "../ShortTxnId";
import { blueGrey } from "@mui/material/colors";



type ComponentProps = {
    escrow: IEscrowInfo | null | undefined;
};
const EscrowLifecycle: React.FC<ComponentProps> = ({ escrow }) => {
    dayjs.extend(utc);

    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();


    const {
        isActive, isEffective, isSettled, isExpired,
        isPartiallyFunded, isFullyFunded,
        isParty1Settled, isParty2Settled,
        isInFundingPeriod, isInSettlementPeriod, isInReleasePeriod
    } = escrowStatus(escrow);



    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h3 = isLg ? "h3" : (isMd ? "h4" : "h5");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");

    const body2Sx = {
        fontSize: isMd ? "10.5pt" : (isSm ? "9pt" : "8pt"),
    }
    return (
<Fragment>
<Box sx={{ p: 2, border: '2px solid #1976d2', margin: "10px", backgroundColor: "#f5f5f5" }}>
    <Typography variant={h6}>Escrow Lifecycle / Status</Typography>
    (<Typography variant="caption" color="#1976d2" fontSize={"12pt"}><ShortTxnId id={escrow?.escrowId} /></Typography>)
    <br /><br />
    <Grid container spacing={1} textAlign={"left"} sx={{ display: "flex", alignItems: "flex-end" }}>

        <Grid item xs={2}>
            <Typography variant="body2" sx={body2Sx}>Created</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography variant="body2" sx={body2Sx}>Effective</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography variant="body2" sx={body2Sx}>Earliest Settlement Date</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography variant="body2" sx={body2Sx}>Settlement</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography variant="body2" sx={body2Sx}>Expiry</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography variant="body2" display="inline" sx={body2Sx}>Release </Typography>
            <Typography variant="caption" sx={{fontSize: isMd ? null : "7pt", color: "blue"}}>*if expired</Typography>
        </Grid>

        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>
        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>
        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>
        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>
        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>
        <Grid item xs={2} borderLeft="4px solid blue" borderBottom="4px solid blue"><br /></Grid>

        <Grid item xs={12}></Grid>

        

        <Grid item xs={6} bgcolor={"#d0d0fa"} textAlign={"center"}>
            <Typography variant="caption" color={isActive ? "black" : "gray"} sx={body2Sx}>
                IsActive {isActive ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2} bgcolor={"#d0fad0"} textAlign={"center"}>
            <Typography variant="caption" color={isSettled ? "black" : "gray"} sx={body2Sx}>
                IsSettled {isSettled ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2} bgcolor={"#fad0d0"} textAlign={"center"}>
            <Typography variant="caption" color={isExpired ? "black" : "gray"} sx={body2Sx}>
                IsExpired {isExpired ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2}></Grid>



        <Grid item xs={2}></Grid>
        <Grid item xs={8} bgcolor={"#d0fafa"} textAlign={"center"}>
            {
                (!isPartiallyFunded && !isFullyFunded) ? (
                    <Typography variant="caption" color="gray" sx={body2Sx}>
                        Is Funded
                    </Typography>
                ) : null
            }
            {
                (isPartiallyFunded && !isFullyFunded) ? (
                    <Typography variant="caption" color="black" sx={body2Sx}>
                        Is Partially Funded <CheckCircleIcon style={{fontSize: 14, color: "green"}}/>
                    </Typography>
                ) : null
            }
            {
                (isFullyFunded && !isPartiallyFunded) ? (
                    <Typography variant="caption" color="black" sx={body2Sx}>
                        Is Fully Funded <CheckCircleIcon style={{fontSize: 14, color: "green"}}/>
                    </Typography>
                ) : null
            }
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={4} bgcolor={"#fad0fa"} textAlign={"center"}>
            <Typography variant="caption" color={isEffective ? "black" : "gray"} sx={body2Sx} >
                IsEffective {isEffective ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={6}></Grid>



        <Grid item xs={2} bgcolor={"#d0d0d0"} textAlign="center">
            <Typography variant="caption" color={isInFundingPeriod ? "black" : "gray"} sx={body2Sx} >
                Funding Period {isInFundingPeriod ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} bgcolor={"#d0d0d0"} textAlign="center">
            <Typography variant="caption" color={isInSettlementPeriod ? "black" : "gray"} sx={body2Sx} >
                Settlement Period {isInSettlementPeriod ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} bgcolor={"#d0d0d0"} textAlign="center">
            <Typography variant="caption" color={isInReleasePeriod ? "black" : "gray"} sx={body2Sx} >
                Release Period {isInReleasePeriod ? <CheckCircleIcon style={{fontSize: 14, color: "green"}}/> : null}
            </Typography>
        </Grid>
        <Grid item xs={2}></Grid>

    </Grid>
</Box>
</Fragment>
    )
}
export default EscrowLifecycle;