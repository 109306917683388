import  { Fragment, useEffect, }          from "react";
import  { Button, Typography, Grid, Avatar, CardMedia, CardContent, CardActions, Card, Link,
}                               from "@mui/material";
import  Typewriter              from 'typewriter-effect';

import  { IEasyStepUi, IExtendedChainInfo }         from "../../interfaces";
import  * as Theme              from "../EskrowTheme";
import  * as Helpers            from "../../helpers/general/Helpers";
import  { useWeb3ClientLogic }  from "../../hooks";



type ComponentProps = {
    setPageName?: (newValue: string) => void,
    seoChain?: IExtendedChainInfo,
};
const ContentMain: React.FC<ComponentProps> = ({ setPageName, seoChain }) => {
    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();
    const mediaType = Theme.getMediaType();
    const { supportedChains, connectedChainId, canonical } = useWeb3ClientLogic("");

    Helpers.setPageMeta({
        title:          `${seoChain?.chainName} | Crypto Escrow dApp for any ERC-20 Tokens | Eskrow`,
        description:    `FREE crypto escrow service for ${seoChain?.chainName} tokens. Trustless dApp | Peer to Peer | On Chain | Decentralized`,
        canonical:      location.href,
    });


    
    const uiSteps: IEasyStepUi[]    = [
        {   label:          "1. CREATE",
            description:    "Specify the details of the agreement --the who, what and when."
        },
        {   label:          "2. FUND",
            description:    "Parties can TRUSTFULLY fund the agreed tokens into Eskrow."
        },
        {   label:          "3. SETTLE",
            description:    "Settle the Escrow to withdraw the agreed assets."
        },
    ];

    

    // Onload ...
    // useEffect(() => {
    //     setPageName?.("Main");
    // }, []);

    const h1 = isLg ? "h1" : (isMd ? "h2" : "h3");
    const h2 = isLg ? "h2" : (isMd ? "h3" : "h4");
    const h4 = isLg ? "h4" : (isSm ? "h5" : "subtitle1");
    const h5 = isLg ? "h5" : (isSm ? "h6" : "body1");
    const h6 = isLg ? "h6" : (isSm ? "subtitle2" : "body2");

    return (
        <>
            {/* ----- MAIN Display area */}
            {isMd ? (<><br/><br/><br /></>) : <br /> }
            <Grid container spacing={6} sx={{color: "#202020"}}>
                <Grid item xs={12} sm={6} textAlign={isSm ? "right" : "center"}>
                    <Typography variant={h1} color="#1976d2">
                        <b>
                            <Typewriter
                                options = {{autoStart: true, loop: true,}}
                                onInit  = {(typewriter) => {
                                    typewriter
                                    .typeString('CRYPTO')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('TRUSTLESS')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('Any ERC-20')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('Multi-Chain')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('Web3 dApp')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('Open Source')
                                    .pauseFor(2000).deleteAll()
                                    .typeString('Peace of Mind')
                                    .pauseFor(2000).deleteAll()
                                    .start();
                                }}
                            />
                        </b>
                    </Typography>
                    <Typography variant={h1} color="#4a4a4a">
                            <b>ESCROW</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={"left"} alignItems={"center"}>
                    <Grid container spacing={2}  alignItems={"center"} textAlign={isSm ? "left" : "center"}>
                        <Grid item xs={12} borderLeft={"3px solid #8080a0"}>
                            <Typography variant={h2} color="#000000">
                                Crypto Escrow dApp
                                {isMd ? (<><br/></>) : " " }
                            </Typography>
                            <Typography variant={h4} color="#303030">
                                for the <span style={{ color: "#1976d2" }}><b>
                                {seoChain?.chainName}</b></span> (EMV) Blockchain.
                            </Typography>
                            {isMd ? (<><br/><br/></>) : <br /> }

                            <Typography variant={h4} color="#606060">
                                <b>FREE</b> To Use (
                                <Button 
                                    variant = "contained"
                                    color   = "success"
                                    size    = {isMd ? "medium" : "small"}
                                >Pay Only Gas</Button>)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>



                {/* SELECT CHAIN */}
                <Grid item xs={12} textAlign={"center"}>
                    <Typography variant="caption" fontSize={"10pt"}>Select Chain</Typography><br />
                    {
                            [true, false].map( (supported) => 
                                [false, true].map( (testNet) => 
                                    supportedChains
                                    .filter( (c) => (c.isEnabled === supported && c.isTestNet === testNet))
                                    .map( (chain) =>
                                    {
                                        return (
                                            <Fragment key       = {"f" + chain.chainId}>
                                                <Link href      = {Helpers.buildUrl( `/${chain.urlLink}`, [] )} 
                                                    component   = {Button}
                                                >
                                                    <Button variant = "outlined"
                                                        sx      = {{    fontSize:       !testNet ? (isSm ? "11pt" : "9pt") : (isSm ? "11pt": "8pt"),
                                                                        padding:        isSm ? "5px" : "2px", 
                                                                        margin:         isSm ? "5px" : "2px",
                                                                        textTransform:  "none",
                                                                        border:         chain.chainId === connectedChainId ? "4px solid #1976d2" : !supported ? "1px solid #c0c0c0" : "1px solid #1976d2",
                                                                        color:          supported ? "#606060" : "#c0c0c0",
                                                                        backgroundColor:testNet ? "#c0c0c0" : null,
                                                                        whiteSpace:     "pre-line",
                                                        }}
                                                        disabled    = {!supported}
                                                        startIcon   = {<Avatar src={chain.iconUrl} style={{ width: '20px', height: '20px' }}/>}
                                                    >
                                                        {chain.chainName}
                                                        { !testNet ? null
                                                            : (
                                                                <Typography variant="caption" fontSize="8pt" color="blue" marginLeft="3px">
                                                                    (TestNet)
                                                                </Typography>
                                                                )
                                                        }
                                                        { supported ? null
                                                            : (
                                                                <Typography variant="caption" fontSize="8pt" color="red" marginLeft="3px">
                                                                    (Soon)
                                                                </Typography>
                                                                )
                                                        }
                                                    </Button>
                                                </Link>
                                            </Fragment>
                                            )
                                    })
                                )
                            )
                        }
                </Grid>



                {/* LAUNCH dAPP BUTTON */}
                <Grid item xs={12} textAlign={"center"} paddingBottom={"48px"}>
                    <Link href={"/app"}>
                        <Button variant = "contained"
                                size    = "large"
                                sx      = {{
                                    fontSize:       "14pt",
                                    textTransform:  "none",
                                }}
                        >
                            Launch dApp
                        </Button>
                    </Link>
                </Grid>



                <Grid item xs={12} marginTop = {"5px"} marginBottom={"0px"} sx = {{ backgroundColor: '#f0f0f0', }}>
                    <Typography
                        variant         = "h4"
                        color           = "#808080"
                        sx=         {{ 
                                        fontSize:       "18pt",
                                        fontWeight:     "bold",
                                    }}
                    >
                        ESKROW, AS FEATURED IN
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4} 
                    textAlign       = {"center"}
                    justifyContent  = {"center"}
                    paddingRight    = {"15px"}
                    sx              = {{ 
                        backgroundColor:    '#f0f0f0', 
                        display:            'flex',
                    }}
                >
                    <Card sx    = {{
                        display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                    <CardMedia
                        sx      = {{ height: 100, width: 190, marginTop: 'auto', marginBottom: 'auto' }}
                        image   = "/assets/Featured-Eskrow-WalletConnect.png"
                        title   = "Eskrow @ WalletConnect"
                    />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            WalletConnect
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                An open-source protocol that facilitates secured connection between wallets and dApps.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Link href      = "https://walletconnect.com/explorer/eskrow-decentralized-trustless-on-chain-escrow-dapp" 
                                component   = {Button} color = "secondary"
                            >
                                <Button variant="contained" sx={{textTransform: "none"}} size="small">
                                    Go
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} 
                    textAlign       = {"center"}
                    justifyContent  = {"center"}
                    paddingRight    = {"15px"}
                    sx              = {{ 
                        backgroundColor:    '#f0f0f0', 
                        display:            'flex',
                    }}
                >
                    <Card sx    = {{
                        display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                    <CardMedia
                        sx      = {{ height: 100, width: 190, marginTop: 'auto', marginBottom: 'auto' }}
                        image   = "../assets/Featured-Eskrow-Gate.png"
                        title   = "Eskrow @ Gate.io"
                    />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Gate.io
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Gate.io is a prominent crypto exchange that offers wide range of trading pairs.
                                </Typography>
                        </CardContent>
                        <CardActions>
                            <Link href      = "https://www.gate.io/web3/dapps" 
                                component   = {Button} color = "secondary"
                            >
                                <Button variant="contained" sx={{textTransform: "none"}} size="small">
                                    Go
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} 
                    textAlign       = {"center"}
                    justifyContent  = {"center"}
                    paddingRight    = {"15px"}
                    sx              = {{ 
                        backgroundColor:    '#f0f0f0', 
                        display:            'flex',
                    }}
                >
                    <Card sx    = {{
                        display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                    <CardMedia
                        sx      = {{ height: 100, width: 190, marginTop: 'auto', marginBottom: 'auto' }}
                        image   = "../../assets/Featured-Eskrow-DappRadar.png"
                        title   = "Eskrow @ DappRadar"
                    />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            DappRadar
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                DappRadar is a popular platform that tracks, analyzes, and provides insights on dApps.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Link href      = "https://dappradar.com/dapp/eskrow-la/about" 
                                component   = {Button} color = "secondary"
                            >
                                <Button variant="contained" sx={{textTransform: "none"}} size="small">
                                    Go
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
                {
                    isMd ? null
                    : (
                        <Grid item xs={12} md={0} 
                            textAlign       = {"center"}
                            justifyContent  = {"center"}
                                sx              = {{ 
                                backgroundColor:    '#f0f0f0',
                                display:            'flex',
                            }}
                        />
                    )
                }
            </Grid>
            <br/><br/>



            {/* ----- WHO SENDS FIRST area */}
            <Grid   container
                    spacing = {3}
                    style   = {{    backgroundColor : "#1976d2", 
                                    color           : "white", 
                                    alignItems      : "center" 
                                }}
            >
                <Grid item sm={4} xs={12} style={{ textAlign: "center" }}>
                    <Typography variant={h2}>
                        <b>WHO SENDS FIRST?</b>
                    </Typography>
                    {isMd ? (<br/>) : null }
                </Grid>
                <Grid item sm={8} xs={12} style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2">
                    {isMd ? (<br/>) : null }
    <b>Eskrow</b> is a crypto escrow dApp that  {Helpers.randomOne(["allows", "enables"])} two parties
    to {Helpers.randomOne(["exchange", "swap"])} tokens in the <b>{seoChain?.chainName}</b>{" "}
    blockchain without {Helpers.randomOne(["needing", "having"])} to 
    trust that the {Helpers.randomOne(["counterparty", "other party"])} will honor
    the {Helpers.randomOne(["swap", "exchange","agreement"])} after 
    you {Helpers.randomOne(["made", "initiated", "do"])} the transfer.
    <br />
    <br />
    Instead, both parties deposit the agreed (<b>{seoChain?.chainName}</b> based){" "}
    {Helpers.randomOne(["tokens", "ERC20 assets"])} into Eskrow smart contract for 
    safe-keeping until both {Helpers.randomOne(["parties", "sides"])} fully {Helpers.randomOne(["transfer", "deposit"])} the
    agreed {seoChain?.chainName} {Helpers.randomOne(["tokens", "assets"])}.
    <br /><br/>
                    </Typography>
                    <Typography variant={h6}>
                        <i>No More Centralized Middle Party. Eskrow is Fully Decentralized.</i>
                    </Typography>
                    <br /><br />
                </Grid>
            </Grid>



            {
                // ----- FREE TO USE area
            }
            <Grid   container
                    spacing = {3}
                    style   = {{    backgroundColor : "white", 
                                    color           : "#1976d2", 
                                    alignItems      : "center" 
                                }}
            >
                <Grid item xs={12} sm={8} style = {{ textAlign: "right" }}>
                    <Typography variant="subtitle2">
                        {isMd ? (<><br/><br/></>) : null }
    Eskrow is <b>FREE</b> to use. { Helpers.randomOne(["No", "Zero"]) } Deposit
    Fee. { Helpers.randomOne(["No", "Zero"]) } Withdrawal Fee. { Helpers.randomOne(["No", "Zero"]) } Escrow 
    Charges.
    <br/>
    Only pay for the Gas Fee to execute the Eskrow smart contract. Absolultely FREE.
    <br /><br /><br />
                    </Typography>
                    <Typography variant={h6}>
                        <i>Zero Fee. Receives Whatever That Was Deposited.</i>
                    </Typography>
                    {isMd ? (<><br/><br/></>) : null }
                </Grid>
                <Grid item xs={12} sm={4} style={{ textAlign: isMd ? "left" : "center" }}>
                    {isMd ? (<><br/><br/></>) : null }
                    <Typography variant={h2}>
                        ABSOLUTELY
                    </Typography>
                    <Typography variant={h1}>
                        <b>FREE</b>
                    </Typography>
                    <br/><br/>
                </Grid>
            </Grid>



            {/* ----- EASY TO USE area */}
            <Grid   container
                    spacing = {3}
                    style   = {{    backgroundColor : "#1976d2", 
                                    color           : "white", 
                                    alignItems      : "center" 
                                }}
            >
                <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
                    {isMd ? (<><br/><br/></>) : null }
                    <Typography variant={h2}>
                        <b>EASY </b>
                        {isMd ? (<><br/></>) : null }
                        TO USE
                    </Typography>
                    {isMd ? (<><br/><br /><br/></>) : null }
                </Grid>
                {
                    uiSteps.map( (step, index) => (
                        <Grid item xs={4} sm={3} style={{ textAlign: "center" }} key={index}>
                            {isMd ? (<><br/><br/><br/><br/></>) : null }
                            <Typography variant={h4}>
                                {step.label}
                            </Typography>
                            <Typography variant="body2">
                                {step.description}
                                <br/><br/><br/>.
                            </Typography>
                        </Grid>
                    ) )
                }
                <br/><br/><br/>
            </Grid>



            {/* -----USE CASE area */}
            <Grid   container
                    spacing = {3}
                    style   = {{    backgroundColor : "white", 
                                    color           : "#606060", 
                                    alignItems      : "center" 
                                }}
            >
                <Grid item xs={12} sm={8} style={{ textAlign: "right" }}>
                    <Typography variant="subtitle2">
                        {isMd ? (<><br/><br/></>) : null }
                        <b>Private</b> Token { Helpers.randomOne(["Swap", "Exchange"]) } {" "}
                        ({ Helpers.randomOne(["without", "outside of"]) } Centralized Exchanges) {" "}
                        on {seoChain?.chainName} blockchain.
                        <br /><br />
                        <b>Safe</b> P2P BTC&lt;&gt;USDT trades via wBTC. {" "}
                        <br /><br />
                        <b>OTC</b> Trades. {" "}
                        <b>Institutional</b> Settlement. {" "}
                        OTC <b>Options</b> Trading. {" "}
                        OTC <b>Dark Pools</b> / <b>Block Trades</b>.
                        <br/><br/>
                        ICO <b>Pre-Sale</b> (Spot, Vesting or <b>Time-Lock</b> Transactions).<br/>
                    </Typography>
                    <Typography variant="caption" color="#1976d2">
                        * Eskrow only <b>trustlessly</b> { Helpers.randomOne(["facilitates", "handles", "enforces"]) } the  {" "}
                        { Helpers.randomOne(["escrow", "transfer", "safe keeping", "agreement"]) } {" "} 
                        of the {seoChain?.chainName} assets/tokens, and are not involved with the trades themselves.
                    </Typography>
                    {isMd ? (<><br/><br/></>) : null }
                </Grid>
                <Grid item xs={12} sm={4} style={{ textAlign: isMd ? "left" : "center" }}>
                    {isMd ? (<><br/></>) : null }
                    <Typography variant="caption">
                        DECENTRALIZED
                    </Typography>
                    <Typography variant={h1} color="#404040">
                        <b>USE</b>
                    </Typography>
                    <Typography variant={h2} color="#404040">
                        <b>CASES</b>
                    </Typography>
                    <br/>
                </Grid>
                <br/><br/><br/>
            </Grid>
        </>
    );
};

export default ContentMain;