import { useState, useEffect }      from "react";
import { Box, Typography, Tooltip } from "@mui/material";



const useCaseHilite = {
    fontSize:   "12pt",
    fontWeight: "bold",
    color:      "black",
}
const useCaseNormal = {
    fontSize:   "10pt",
    color:      "#1976d2",
}


const RotatingUseCases: React.FC = () => {
    
    const [useCaseHighlightItem, setUseCaseHighlightItem]   = useState(0);
    useEffect(() => {
        const updateUseCaseHighlight = setInterval( () => { 
            setUseCaseHighlightItem((prevItem) => (prevItem % 7) + 1);
        }, 1500 );

        return () => {
            // Clean up Interval timer
            clearInterval(updateUseCaseHighlight);
        };
    }, []);


    
    return (
        <Box sx={{ border: '1px solid #1976d2', margin: "10px", padding: "10px", backgroundColor: "#f0f0f0"}}>
        <Typography variant="caption" textAlign={"center"} fontSize={"12pt"} color={"#1976d2"} >
            <b style={{color: "#404040"}}>USE CASES</b><br />
            | {" "}
            <Tooltip title="Use Eskrow to avoid counterparty not transferring you the agreed tokens (Counterparty Risk)." placement="top"><span>
                    <Typography display={"inline"} sx={useCaseHighlightItem === 1 ? useCaseHilite : useCaseNormal}>
                    {" "} Private Token Exchanges </Typography>
            </span></Tooltip> |
            <Tooltip title="Safely buy/sell USDT <> BTC via wBTC." placement="top"><span>
                    <Typography display={"inline"} sx={useCaseHighlightItem === 2 ? useCaseHilite : useCaseNormal}>
                    {" "} Buy/Sell BTC &lt;&gt; USDT, Safely</Typography>
            </span></Tooltip> |
            <Tooltip title="Use Eskrow to avoid Counterparty Risk. Solves the Who Send First? question." placement="top"><span>
                <Typography display={"inline"} sx={useCaseHighlightItem === 3 ? useCaseHilite : useCaseNormal}>
                {" "} OTC Transactions </Typography>
            </span></Tooltip> |
            <Tooltip title="Eliminate Counterparty Risk no matter the settlement size, and when." placement="top"><span>
                <Typography display={"inline"} sx={useCaseHighlightItem === 4 ? useCaseHilite : useCaseNormal}>
                {" "} Inter-Dealer Settlements </Typography>
            </span></Tooltip> |
            <Tooltip title="Use Eskrow to safely Sell OTC Options." placement="top"><span>
            <Typography display={"inline"} sx={useCaseHighlightItem === 5 ? useCaseHilite : useCaseNormal}> 
                {" "} OTC Options </Typography>
                </span></Tooltip> |
            <Tooltip title="Use Eskrow as 3rd Party to lock funds (stable vs stable), to create trading lines between 2 dealers." placement="top"><span>
            <Typography display={"inline"} sx={useCaseHighlightItem === 6 ? useCaseHilite : useCaseNormal}> 
                {" "} Inter-Dealer Credit Line </Typography>
                </span></Tooltip> |
            <Tooltip title="Safely ensures that Projects will send agreed tokens by a specific date/time (Vesting)." placement="top"><span>
            <Typography display={"inline"} sx={useCaseHighlightItem === 7 ? useCaseHilite : useCaseNormal}> 
                {" "} ICO Pre-Sale </Typography>
            </span></Tooltip>
            |
        </Typography>
        </Box>
    )
};

export default RotatingUseCases;