import  { Link, Typography, }   from "@mui/material";
import  GitHubIcon              from '@mui/icons-material/GitHub';
import  TelegramIcon            from '@mui/icons-material/Telegram';
import  EmailIcon               from '@mui/icons-material/Email';
import  * as Theme              from "./EskrowTheme";



const Footer: React.FC = () => {
    const { theme: eskrowTheme, isXl, isLg, isMd, isSm, isXs } = Theme.useEskrowTheme();

    return (
        <>
            <hr />
            <Typography 
                variant     = "caption"
                color       = "#808080"
                fontSize    = { isMd ? "9pt" : "6pt"}
                // sx          = {{ display: "flex", }}
            >
                <div>
                Eskrow React v1.4.4 | Open Source.
                Licensed under 
                the GNU General Public License version 2 (GPLv2).
                The community is free to use, study, share, and modify it. However, derivative works 
                must also be licensed under the GPLv2.

                </div>
                <TelegramIcon style={{ marginLeft: "8px", marginRight: "8px", verticalAlign: "middle" }} />
                <Link href="https://t.me/eskrow_channel">Announcements</Link>.

                <TelegramIcon style={{ marginLeft: "8px", marginRight: "8px", verticalAlign: "middle" }} />
                <Link href="https://t.me/eskrow_support">Support</Link>.

                <EmailIcon style={{ marginLeft: "8px", marginRight: "8px", verticalAlign: "middle" }} />
                <Link href="mailto:eskrow.la@gmail.com">eskrow.la@gmail.com</Link>.

                | <Link href={"smartContract"}>Source Code</Link> |

                | <Link href={"project" + location.pathname}>Project</Link> |

                <GitHubIcon style={{ marginLeft: "8px", marginRight: "8px", verticalAlign: "middle" }} />
                (soon).

            </Typography>
        </>
    )
};
export default Footer;