import  { Typography }  from "@mui/material";
import  dayjs           from "dayjs";
import  utc             from 'dayjs/plugin/utc';
import  { getDateTimeFromGmtEpoch } from "../../helpers";



const ColumnDateNIn = (
    dateEpochInGmt:     bigint | undefined, 
    overText:           string = "Past", 
    overTextColor:      string = "red",
    isHideDate:         boolean = false,
) => {
    dayjs.extend(utc);

    let dateParam   = getDateTimeFromGmtEpoch(dateEpochInGmt);
    const now       = dayjs();

    let inStr       = "";
    let years       = 0;
    let days        = 0;
    let hours       = 0;
    let minutes     = 0;
    years       = dateParam.diff(now, "years");
    days        = dateParam.diff(now, "days") % 365;
    hours       = dateParam.diff(now, "hours") % 24;
    minutes     = dateParam.diff(now, "minutes") % 60;
    if( years > 0)      inStr += ` ${years}yrs`;
    if( days > 0)       inStr += ` ${days}days`;
    if( hours > 0)      inStr += ` ${hours}hrs`;
    if( minutes > 0)    inStr += ` ${minutes}min`;

    return (
        <span>
            {isHideDate
            ? null
            : (
                dateEpochInGmt?.toString() === "0"
                ? <div>Immediate</div>
                : <div>{dateParam.utc().format("DD MMM YY, hh:mm:ss A")}<br /></div>
            )}
            <Typography variant="caption" color={inStr === "" ? overTextColor : "#1976d2"}>
                {inStr === "" ? overText : ("In " + inStr)}
            </Typography>
        </span>
    )
}

export default ColumnDateNIn;